/** @format */

import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {useOktaAuth} from '@okta/okta-react';
import {BACKEND_URL} from '../../misc/variables.js';

const axios = require('axios');

export const useSekreter = () => {
	const sekreter = useSelector((state) => state.sekreter);

	return sekreter;
};

export const useLoggedInSekreterer = async () => {
	const {authState} = useOktaAuth();

	let s = axios
		.get(`${BACKEND_URL}getLoggedInSekreterer`, {
			params: {token: authState.accessToken},
		})
		.then((res) => {
			return res.data.map((s) => s.navn);
		})
		.catch((err) => {
			console.log(err);
		});

	let f;
	await s.then((res) => {
		f = res;
	});
	return f;
};

export const useAuthState = () => {
	const {authState} = useOktaAuth();

	return authState;
};

export const useInterval = (callback, delay) => {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};

export const useActiveElement = () => {
	const [active, setActive] = React.useState(document.activeElement);

	const handleFocusIn = (e) => {
		setActive(document.activeElement);
	};

	const handleFocusOut = (e) => {
		setActive(null);
	};

	React.useEffect(() => {
		document.addEventListener('focusin', handleFocusIn);
		document.addEventListener('focusout', handleFocusOut);
		return () => {
			document.removeEventListener('focusin', handleFocusIn);
			document.removeEventListener('focusout', handleFocusOut);
		};
	}, []);

	return active;
};
