/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import history from './history';
import {BrowserRouter as Router} from 'react-router-dom';

// Redux:
import {createStore} from 'redux';
import {Provider} from 'react-redux';
import allReducers from './reducers';

// Store -> Globalized state

// Action -> Stuff to do on state

// Reducer -> Decribes how state transforms into another state
let store = createStore(
	allReducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// store.subscribe(() => console.log(store.getState()));
// Dispatch -> Actually perforns the action

ReactDOM.render(
	<Provider store={store}>
		<Router>
			<App history={history} />
		</Router>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
