/** @format */

const bedriftReduser = (
	state = {
		bedriftID: '',
		uniMID: '',
		trioID: '',
		synlig: true,
		firmaNavn: '',
		adr1: '',
		adr2: '',
		poststed: '',
		veibeskrivelse: '',
		telefon: '',
		epost: '',
		rapporteringsEpost: '',
		infoWeb: '',
		varWeb: '',
		snarvei1txt: '',
		snarvei1: '',
		snarvei2txt: '',
		snarvei2: '',
		snarvei3txt: '',
		snarvei3: '',
		regNrFirmaregisteret: '',
		sekreterID: '',
		bedriftDriverMed: '',
		info1: '',
		info2: '',
		sluttrapportMail: true,
		sluttrapportExcel: true,
		mndStatistikk1: true,
		mndstatistikk2: true,
		mndstatistikk3: true,
		statistikkNavn: '',
		statistikkEpost: '',
		set: false,
	},
	action
) => {
	const defaultState = {
		bedriftID: '',
		uniMID: '',
		trioID: '',
		synlig: true,
		firmaNavn: '',
		adr1: '',
		adr2: '',
		poststed: '',
		veibeskrivelse: '',
		telefon: '',
		epost: '',
		rapporteringsEpost: '',
		infoWeb: '',
		varWeb: '',
		snarvei1txt: '',
		snarvei1: '',
		snarvei2txt: '',
		snarvei2: '',
		snarvei3txt: '',
		snarvei3: '',
		regNrFirmaregisteret: '',
		sekreterID: '',
		bedriftDriverMed: '',
		info1: '',
		info2: '',
		sluttrapportMail: true,
		sluttrapportExcel: true,
		mndStatistikk1: true,
		mndstatistikk2: true,
		mndstatistikk3: true,
		statistikkNavn: '',
		statistikkEpost: '',
		set: false,
	};
	switch (action.type) {
		case 'UPDATE_BEDRIFT':
			for (let i = 0; i < Object.keys(action.payload).length; i++) {
				state = {
					...state,
					[Object.keys(action.payload)[i]]:
						action.payload[Object.keys(action.payload)[i]],
				};
			}
			return state;
		case 'UPDATE_BEDRIFT_SET':
			state = {...state, set: action.payload};
			return state;
		case 'SET_BEDRIFT_DEFAULT':
			return defaultState;
		default:
			return state;
	}
};

export default bedriftReduser;
