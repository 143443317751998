/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

// Redux stuff
import {
	updateSekreter,
	setUserInfo,
	setUserInfoNone,
	setDefaultSekreter,
} from '../../actions';

// Component imports
import RegisterForm from './RegisterForm.jsx';
import NavBar from '../Navbar/Navbar';
import EndreAnsatt from './EndreAnsatt.jsx';
import GenerateKundeInfo from './GenerateKundeInfo.jsx';
import ImportAnsatte from './ImportAnsatte';
import GenerateReports from './GenerateReports.jsx';
import EndreSekreter from './EndreSekreter';
import GenererLonn from './GenererLonn.jsx';
import ImportBedrifter from './ImportBedrifter';

// Misc
import {BACKEND_URL} from '../../misc/variables.js';

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	background-color: #d2dbdd;
	display: grid;
	/*grid-template-areas:
		'. . header . .'
		'. . . . .'
		'menu . content content .'
		'. . content content .'
		'. . content content .'
        '. . content content .';*/
	grid-template-areas: 'menu content';
	grid-template-columns: 250px 1fr;
	// grid-auto-columns: 15% 85%;
	grid-auto-rows: minmax(autofit, min-content, max-content);
	margin-top: 10px;
`;

const Header = styled.h1`
	margin-left: 45%;
`;

const Div = styled.div`
	height: 100vh;
	width: 100vw;
	background-color: #d2dbdd;
`;

const Menu = styled.div`
	/*grid-row-start: 1;
	grid-column-start: 1;
    justify-self: center;
    */
	grid-area: menu;
`;

const Content = styled.div`
	/*grid-column-start: 2;
	gird-row-start: 1;
	align-items: center;
	& > h2,
	h3 {
		margin-left: 30%;
    }*/
	grid-area: content;
	align-items: center;
	justify-content: center;
`;

const Ul = styled.ul`
	list-style-type: none;
	& > li {
		color: blue;
		cursor: pointer;
		text-decoration: underline;
	}
`;

const Admin = ({history}) => {
	const {authState, authService} = useOktaAuth();
	const userInfo = useSelector((state) => state.userInfo);
	const [bedrifter, setBedrifter] = useState();
	const [bedriftChosen, setBedriftChosen] = useState(false);
	const [bedriftID, setBedriftID] = useState();
	const dispatch = useDispatch();

	const defaultState = {
		register: false,
		endreKunde: false,
		importerAnsatte: false,
		generateKundeInfo: false,
		genererRapporter: false,
		endreSekreter: false,
		importBedrifter: false,
	};

	useEffect(() => {
		const getUser = async () => {
			if (!authState.isAuthenticated) {
				// When user isn't authenticated, forget any user info
				dispatch(setUserInfoNone());
			} else {
				if (userInfo.name === '') {
					await authService
						.getUser()
						.then(async (info) => {
							if (userInfo.name === '' && info === undefined) {
								alert('Det anbefales at du logger ut og inn!');

								dispatch(
									setUserInfo(
										JSON.parse(
											localStorage.getItem('userInfo')
										)
									)
								);
							} else {
								if (info !== undefined) {
									dispatch(setUserInfo(info));
									localStorage.setItem(
										'userInfo',
										JSON.stringify(info)
									);
								} else {
									dispatch(setUserInfo(info));
								}
							}
							await axios
								.get(
									`${BACKEND_URL}getSekreter/${
										info === undefined
											? userInfo.email
											: info.email
									}`,
									{
										headers: {
											authorization: `Bearer ${await authService.getAccessToken()}`,
										},
									}
								)
								.then(async (res) => {
									if (res.data === 'No connection') {
										dispatch(setDefaultSekreter());
									}
									dispatch(
										updateSekreter(res.data.sekreter[0])
									);
								});
						})
						.catch(async (err) => {
							console.log(err);
						});
				}
			}
		};
		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Update if authState changes

	const [visible, setVisible] = useState(defaultState);

	const handleVisible = (e) => {
		setVisible({...defaultState, [e.target.id]: !visible[e.target.id]});
	};

	const getBedrifter = async () => {
		await axios
			.get(BACKEND_URL + 'getBedrifter', {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then((res) => {
				if (res === 'No connection') {
					console.log('Ikke kontakt med databasen');
				} else {
					let temp = [];
					for (let i = 0; i < res.data.length; i++) {
						temp.push({
							label: res.data[i].firmaNavn,
							value: res.data[i].bedriftID.toString(),
						});
					}
					setBedrifter(temp);
				}
			})
			.catch((err) => {
				if (err.response.status === 500) {
					console.log('Får ikke kontakt med databasen');
				}
				console.log(err);
			});
	};

	const handleBedriftChange = (e, {action}) => {
		switch (action) {
			case 'clear':
				setBedriftChosen(false);
				break;
			default:
				setBedriftChosen(true);
				setBedriftID(e.value);
		}
		console.log(e);
	};

	return (
		<Div>
			<NavBar history={history} /> <Header>Administrasjon</Header>
			{userInfo && userInfo.groups.includes('Admin') ? (
				<Wrapper>
					<Menu>
						<Ul>
							<li id='register' onClick={handleVisible}>
								Registrer en ny sekreter
							</li>
							<li id='endreKunde' onClick={handleVisible}>
								Endre kunde
							</li>
							<li id='generateKundeInfo' onClick={handleVisible}>
								Exporter kunders ansatte
							</li>
							<li id='importerAnsatte' onClick={handleVisible}>
								Importer ansatte
							</li>
							<li id='genererRapporter' onClick={handleVisible}>
								Generer rapporter
							</li>
							<li id='endreSekreter' onClick={handleVisible}>
								Endre sekretær
							</li>
							<li id='genererLonn' onClick={handleVisible}>
								Generer lønnsgrunnlag
							</li>
							<li>
								<a
									href={
										BACKEND_URL +
										`generateTrio?token=${authState.accessToken}`
									}>
									Last ned Trio-csv
								</a>
							</li>
							<li>
								<a
									href={
										BACKEND_URL +
										`downloadBedrifter?token=${authState.accessToken}`
									}>
									Last ned bedrifter
								</a>
							</li>
							<li id='importBedrifter' onClick={handleVisible}>
								Importer bedrifter
							</li>
						</Ul>
					</Menu>
					{visible.register ? (
						<Content>
							<h2>Registrer ny sekreter</h2>
							<RegisterForm />
						</Content>
					) : visible.endreKunde ? (
						<Content>
							<h2>Endre kunde</h2>
							<EndreAnsatt />
						</Content>
					) : visible.generateKundeInfo ? (
						<Content>
							<h2>Generer excel med kundeinfo</h2>
							<GenerateKundeInfo
								authService={authService}
								getBedrifter={getBedrifter}
								handleBedriftChange={handleBedriftChange}
								bedriftID={bedriftID}
								setBedriftID={setBedriftID}
								bedriftChosen={bedriftChosen}
								bedrifter={bedrifter}
							/>
						</Content>
					) : visible.importerAnsatte ? (
						<Content>
							<h2>Importer Ansatte</h2>
							<ImportAnsatte
								authService={authService}
								getBedrifter={getBedrifter}
								handleBedriftChange={handleBedriftChange}
								bedriftID={bedriftID}
								setBedriftID={setBedriftID}
								bedriftChosen={bedriftChosen}
								bedrifter={bedrifter}
							/>
						</Content>
					) : visible.genererRapporter ? (
						<Content>
							<h2>Generer rapporter</h2>
							<GenerateReports />
						</Content>
					) : visible.endreSekreter ? (
						<Content>
							<h2>Endre sekretær</h2>
							<EndreSekreter />
						</Content>
					) : visible.genererLonn ? (
						<Content>
							<h2>Generer Lønnshrunnlag</h2>
							<GenererLonn />
						</Content>
					) : visible.importBedrifter ? (
						<Content>
							<h2>Importer bedrifter</h2>
							<ImportBedrifter />
						</Content>
					) : (
						<Content>
							<h2>Velkommen, {userInfo.name}</h2>
							<h3>Velg handling fra menyen til venstre</h3>
						</Content>
					)}
				</Wrapper>
			) : (
				<p>Du har ikke tilgang.</p>
			)}
		</Div>
	);
};

export default Admin;
