/** @format */

// React imports
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';

// Helpers
import {processAnsatte, getAlleBedrifter} from '../../misc/helpers.js';
import {BACKEND_URL} from '../../misc/variables.js';

// Component imports
import Select from 'react-select';
import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Alert,
} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
	justify-self: left;
	align-self: left;
	grid-row: 2/2;
`;

const SokBedrift = styled(Select)`
	grid-row: 1/1;
	width: 100%;
	margin-bottom: 2%;
`;

const SokAnsatt = styled(Select)`
	grid-row: 2/2;
`;

const Form1 = styled(Form)`
	grid-row: 3/3;
	margin-top: 1em;
	padding: 1em;
	border: 2px solid #2d93ad;
`;

const CustomAlert = styled.div`
	background-color: #94cbe0;
	padding: 2%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	text-align: center;
	align-items: center;
	display: grid;
	margin: 4%;
	grid-auto-rows: minmax(min-content, max-content);

	table {
		border-spacing: 0;
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			:last-child {
				border-right: 0;
			}
		}
	}
`;

const Alarm = styled(Alert)``;
const EndreAnsatt = () => {
	const [bedrifter, setBedrifter] = useState();
	const [chosenAnsatt, setChosenAnsatt] = useState(false);

	const [chosenBedrift, setChosenBedrift] = useState(false);
	const {authService} = useOktaAuth();

	const defaultAnsatt = {
		ansattID: 0,
		bedriftID: 0,
		fornavn: '',
		etternavn: '',
		telefon1: '',
		telefon2: '',
		stilling: '',
		avdeling: '',
		kontor: '',
		nokkelPerson: 0,
		nokkelStikkord: '',
		visInnringersAnr: 0,
		trioKode: '',
		info1: '',
		info2: '',
		beskjedMail: 0,
		beskjedSms: 0,
		smsNr: '',
		epost: '',
		dagsRapportMail: 0,
		dagsRapportExcel: 0,
	};

	const [ansatt, setAnsatt] = useState(defaultAnsatt);
	const [alleAnsatte, setAlleAnsatte] = useState();
	const [values, setValues] = useState();
	const [visible, setVisible] = useState(false);
	const [skrevetTilVisible, setSkrevetTilVisible] = useState(false);
	const [ansatteWithoutGroups, setAnsatteWithoutGroups] = useState();
	const [ansatteWithGroups, setAnsatteWithGroups] = useState();

	const bedriftInp = useRef(null);
	const ansattInp = useRef(null);

	useEffect(() => {
		getBedrifter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBedrifter = async () => {
		setBedrifter(
			await getAlleBedrifter(await authService.getAccessToken())
		);
	};

	const generateAnsatte = async (id) => {
		await new Promise(async (resolve, reject) =>
			resolve(processAnsatte(id, await authService.getAccessToken()))
		).then((res) => {
			setAnsatteWithoutGroups(res[0]);
			setAnsatteWithGroups(res[1]);
			setValues(res[1]);
			setAlleAnsatte(res[2]);
		});
	};

	const filterOptions = (candidate, value) => {
		if (value === '') {
			setValues(ansatteWithGroups);
			return true;
		} else {
			setValues(ansatteWithoutGroups);
		}
		if (
			candidate.label
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.value
				.toString()
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.data.sokeOrd
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim())
		)
			return true;
	};

	const handleBedriftChange = (e, {action}) => {
		if (ansattInp.current !== null) {
			ansattInp.current.select.clearValue();
		}
		switch (action) {
			case 'clear':
				setAnsatt(defaultAnsatt);
				setChosenAnsatt(false);
				setChosenBedrift(false);
				break;
			default:
				generateAnsatte(parseInt(e.value));
				setChosenBedrift(true);
				setAnsatt(defaultAnsatt);
				break;
		}
	};

	const handleAnsattChange = (e, {action}) => {
		switch (action) {
			case 'clear':
				setChosenAnsatt(false);
				break;
			default:
				const ans = alleAnsatte.filter(
					(a) => a.ansattID === e.value
				)[0];

				for (const [key, value] of Object.entries(ans)) {
					if (value === null) {
						ans[key] = '';
					}
				}
				setAnsatt(alleAnsatte.filter((a) => a.ansattID === e.value)[0]);
				setChosenAnsatt(true);
				break;
		}
	};

	const handleFormChange = (e) => {
		e.target.type === 'checkbox'
			? setAnsatt({...ansatt, [e.target.name]: e.target.checked})
			: setAnsatt({...ansatt, [e.target.name]: e.target.value});
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		confirmAlert({
			customUI: ({onClose}) => (
				<CustomAlert>
					<h1>Endre Ansatt</h1>
					<h3>Sikker på at du vil endre ansatt?</h3>
					<button onClick={onClose}>Nei, jeg vil endre</button>
					<button
						onClick={async () => {
							await axios
								.post(`${BACKEND_URL}changeAnsatt`, ansatt, {
									headers: {
										authorization: `Bearer ${await authService.getAccessToken()}`,
									},
								})
								.then((res) => {
									setVisible(true);
									onClose();
								})
								.catch((err) => {
									console.log(err);
									if (err.response.status === 500) {
										setSkrevetTilVisible(true);
										onClose();
									}
								});
						}}>
						Ja!
					</button>
				</CustomAlert>
			),
		});
	};

	const toggleAlarm = () => {
		setVisible(!visible);
	};

	const toggleSkrevetTil = () => {
		setSkrevetTilVisible(!skrevetTilVisible);
	};

	return (
		<Wrapper>
			<SokBedrift
				isClearable
				isSearchable
				ref={bedriftInp}
				options={bedrifter}
				onChange={handleBedriftChange}
				placeholder='Velg bedrift'
			/>
			{chosenBedrift && (
				<SokAnsatt
					ref={ansattInp}
					isClearable
					isSearchable
					options={values}
					filterOption={filterOptions}
					onChange={handleAnsattChange}
					placeholder='Velg ansatt'
				/>
			)}
			{chosenAnsatt && (
				<Form1 onSubmit={handleFormSubmit}>
					<Alarm
						color='success'
						isOpen={visible}
						toggle={toggleAlarm}>
						{ansatt.fornavn} {ansatt.etternavn} endret!
					</Alarm>
					<Alarm
						color='danger'
						isOpen={skrevetTilVisible}
						toggle={toggleSkrevetTil}>
						Ingen kontakt med databasen. Ansatt blir forsøkt endret
						senere.
					</Alarm>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='Fornavn'>Fornavn</Label>
								<Input
									type='text'
									name='fornavn'
									id='fornavn'
									placeholder='Fornavn'
									value={ansatt.fornavn}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='etternavn'>Etternavn</Label>
								<Input
									type='text'
									name='etternavn'
									id='etternavn'
									placeholder='Etternavn'
									value={ansatt.etternavn}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='epost'>Epost</Label>
								<Input
									type='email'
									name='epost'
									id='epost'
									placeholder='Epost'
									value={ansatt.epost}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='smsNr'>SMS</Label>
								<Input
									type='tel'
									name='snmNr'
									id='smsNr'
									placeholder='SMS'
									value={ansatt.smsNr}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='telefon1'>Telefon 1</Label>
								<Input
									type='text'
									name='telefon1'
									id='telefon1'
									placeholder='Telefon 1'
									value={ansatt.telefon1}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='telefon2'>Telefon 2</Label>
								<Input
									type='text'
									name='telefon2'
									id='telefon2'
									placeholder='Telefon 2'
									value={ansatt.telefon2}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={5}>
							<FormGroup>
								<Label for='stilling'>Stilling</Label>
								<Input
									type='text'
									name='stilling'
									id='stilling'
									placeholder='Stilling'
									value={ansatt.stilling}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={5}>
							<FormGroup>
								<Label for='avdeling'>Avdeling</Label>
								<Input
									type='text'
									name='avdeling'
									id='avdeling'
									placeholder='Avdeling'
									value={ansatt.avdeling}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={2}>
							<FormGroup>
								<Label for='kontor'>Kontor</Label>
								<Input
									type='text'
									name='kontor'
									id='kontor'
									placeholder='Kontor'
									value={ansatt.kontor}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='nokkelStikkord'>
									Nøkkelstikkord
								</Label>
								<Input
									type='textarea'
									name='nokkelStikkord'
									id='nokkelStikkord'
									placeholder='Nøkkelstikkord'
									value={ansatt.nokkelStikkord}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='trioKode'>Trio-kode</Label>
								<Input
									type='text'
									name='trioKode'
									id='trioKode'
									placeholder='Trio-kode'
									value={ansatt.trioKode}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<CustomInput
									type='switch'
									id='visInnringersAnr'
									name='visInnringersAnr'
									label='Vis innringers anr'
									inline
									checked={ansatt.visInnringersAnr}
									onChange={handleFormChange}
								/>
								<CustomInput
									type='switch'
									id='nokkelPerson'
									name='nokkelPerson'
									label='Nøkkelperson'
									inline
									checked={ansatt.nokkelPerson}
									onChange={handleFormChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedMail'
									name='beskjedMail'
									label='Beskjed mail'
									inline
									checked={ansatt.beskjedMail}
									onChange={handleFormChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedSms'
									name='beskjedSms'
									label='Beskjed SMS'
									inline
									checked={ansatt.beskjedSms}
									onChange={handleFormChange}
								/>
								<CustomInput
									type='switch'
									id='dagsRapportMail'
									name='dagsRapportMail'
									label='Dagsrapport mail'
									inline
									checked={ansatt.dagsRapportMail}
									onChange={handleFormChange}
								/>
								<CustomInput
									type='switch'
									id='dagsRapportExcel'
									name='dagsRapportExcel'
									label='Dagsrapport Excel'
									inline
									checked={ansatt.dagsRapportExcel}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='info1'>Info 1</Label>
								<Input
									type='textarea'
									name='info1'
									id='info1'
									placeholder='Info 1'
									value={ansatt.info1}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='info2'>Info 2</Label>
								<Input
									type='textarea'
									name='info2'
									id='info2'
									placeholder='Info 2'
									value={ansatt.info2}
									onChange={handleFormChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Button>Endre</Button>
				</Form1>
			)}
		</Wrapper>
	);
};

export default EndreAnsatt;
