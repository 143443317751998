/** @format */

// React Stuff
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';
import {useOktaAuth} from '@okta/okta-react';
import {toast} from 'react-toastify';

// Redux imports
import {updateBeskjed, setDefaultBeskjed} from '../../actions/beskjed';

// Helpers imports
import {BACKEND_URL} from '../../misc/variables.js';
import {createBeskjed, sendBeskjed} from '../../misc/helpers';

// Components
import Alleskontroll from '../Alleskontroll/Alleskontroll.jsx';
import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Alert,
} from 'reactstrap';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Configure toast
toast.configure();

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	display: grid;
	text-align: left;
	// grid-template-rows: 1fr 15fr;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 100%;
`;

const Beskjed = styled.div`
	display: grid;
	grid-auto-rows: minmax(min-content, max-content);
	grid-row-start: 3;
	width: 100%;
`;

const AlleskontrollWrapper = styled.div`
	grid-row: 4;
`;

const BeskjedHead = styled.span`
	grid-column: 1/1;
	grid-row: 1/1;
	justify-self: left;
	// margin-top: 1em;
`;

const Alarm = styled(Alert)`
	grid-row: 2;
`;

const CustomAlert = styled.div`
	background-color: #94cbe0;
	padding: 2%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	text-align: center;
	align-items: center;
	display: grid;
	margin: 4%;
	grid-auto-rows: minmax(min-content, max-content);

	table {
		border-spacing: 0;
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			:last-child {
				border-right: 0;
			}
		}
	}
`;

const Messagefield = ({sok}) => {
	const [visible, setVisible] = useState(false);
	const [skrevetTilVisible, setSkrevetTilVisible] = useState(false);
	const beskjed = useSelector((state) => state.beskjed);
	const bedriftID = useSelector((state) => state.beskjed.bedriftID);
	const bedrift = useSelector((state) => state.bedrift);
	const ansatt = useSelector((state) => state.ansatt);
	const sekreter = useSelector((state) => state.sekreter);
	const dispatch = useDispatch();
	const {authService} = useOktaAuth();
	const [beforeSattOver, setBeforeSattOver] = useState('');
	const [alleskontrollVisible, setAllesKontrollVisible] = useState(false);

	useEffect(() => {
		dispatch(updateBeskjed({sekreterID: sekreter.sekreterID}));
	}, [sekreter, dispatch]);

	const clearAllInputs = () => {
		sok.current.select.clearValue();
		dispatch(setDefaultBeskjed());
		dispatch(updateBeskjed({sekreterID: sekreter.sekreterID}));
	};

	const send = async (e) => {
		e.preventDefault();
		if (bedriftID === '' || beskjed.ansattID === '') {
			alert(
				'Du må velge en bedrift og en ansatt før du kan sende beskjed til kontroll!'
			);
		} else {
			if (beskjed.sattOver || beskjed.stoppDenne || beskjed.vent) {
				confirmAlert({
					customUI: ({onClose}) => {
						return (
							<CustomAlert>
								<h1>Lagre beskjed uten å sende</h1>
								<p>
									Du har krysset av for satt over og/eller
									bare sluttrapport og/eller vent. Beskjeden
									vil kun lagres. Dersom du har krysset av for
									vent, kan beskjeden endres senere.
								</p>
								<div>
									<strong>Fra:</strong>
									<p>{beskjed.fra}</p>
									<strong>Til:</strong>
									<p>
										{ansatt.fornavn} {ansatt.etternavn}
									</p>
									<strong>Bedrift:</strong>
									<p>{bedrift.firmaNavn}</p>
									<strong>Beskjed:</strong>
									<p>{beskjed.beskjed}</p>
									<strong>Skjult info:</strong>
									<p>{beskjed.skjultInfo}</p>
								</div>
								<button onClick={onClose}>Jeg vil endre</button>
								<button
									onClick={async () => {
										await axios
											.post(
												`${BACKEND_URL}createBeskjed`,
												{
													beskjed: {
														...beskjed,
														retting: beskjed.vent
															? 1
															: 0,
														godkjent:
															beskjed.stoppDenne ||
															beskjed.sattOver
																? 1
																: 0,
														godkjentAv:
															beskjed.stoppDenne ||
															beskjed.sattOver
																? sekreter.sekreterID
																: null,
														tid: new Date(),
													},
												},
												{
													headers: {
														authorization: `Bearer ${await authService.getAccessToken()}`,
													},
												}
											)
											.then((res) => {
												if (res.status === 200) {
													toast.success(
														'Beskjeden er lagret!',
														{
															position:
																'top-right',
															autoClose: 5000,
															hideProgressBar: false,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															progress: undefined,
														}
													);
													clearAllInputs();
													onClose();
												}
											})
											.catch((err) => {
												toast.error(
													'Noe feil skjedde',
													{
														position: 'top-right',
														autoClose: 5000,
														hideProgressBar: false,
														closeOnClick: true,
														pauseOnHover: true,
														draggable: true,
														progress: undefined,
													}
												);
												onClose();
											});
									}}>
									Lagre!
								</button>
							</CustomAlert>
						);
					},
				});
			} else if (
				beskjed.haster ||
				beskjed.bedriftID.toString() === sekreter.bedriftID.toString()
			) {
				confirmAlert({
					customUI: ({onClose}) => {
						return (
							<CustomAlert>
								<h1>Send beskjed til bedrift.</h1>
								<p>
									Denne beskjeden vil ikke gå igjennom
									kontroll. Vil du sende med en gang?
								</p>
								<div>
									<strong>Fra:</strong>
									<p>{beskjed.fra}</p>
									<strong>Til:</strong>
									<p>
										{ansatt.fornavn} {ansatt.etternavn}
									</p>
									<strong>Bedrift:</strong>
									<p>{bedrift.firmaNavn}</p>
									<strong>Beskjed:</strong>
									<p>{beskjed.beskjed}</p>
									<strong>Skjult info:</strong>
									<p>{beskjed.skjultInfo}</p>
								</div>
								<button onClick={onClose}>
									Nei, jeg vil endre
								</button>
								<button
									onClick={async () => {
										createBeskjed(
											{
												...beskjed,
												godkjent: 1,
												godkjentAv: sekreter.sekreterID,
												tid: new Date(),
											},
											await authService.getAccessToken()
										).then(async (res) => {
											if (res.status === 200) {
												let success = await sendBeskjed(
													res.data,
													ansatt,
													sekreter,
													await authService.getAccessToken()
												);
												let message;
												if (
													success.mail &&
													ansatt.beskjedMail &&
													success.sms &&
													ansatt.beskjedSms
												) {
													message =
														'Sendt mail og sms!';
												} else if (
													success.mail &&
													ansatt.beskjedMail
												) {
													message = 'Sendt mail!';
												} else if (
													success.sms &&
													ansatt.beskjedSms
												) {
													message = 'Send sms!';
												}
												if (
													success.mail ||
													success.mail
												) {
													toast.success(message, {
														position: 'top-right',
														autoClose: 5000,
														hideProgressBar: false,
														closeOnClick: true,
														pauseOnHover: true,
														draggable: true,
														progress: undefined,
													});
													clearAllInputs();
													onClose();
												} else {
													toast.error(
														'Noe feil skjedde',
														{
															position:
																'top-right',
															autoClose: 5000,
															hideProgressBar: false,
															closeOnClick: true,
															pauseOnHover: true,
															draggable: true,
															progress: undefined,
														}
													);
													onClose();
												}
											}
										});
									}}>
									Ja!
								</button>
							</CustomAlert>
						);
					},
				});
			} else {
				confirmAlert({
					customUI: ({onClose}) => {
						return (
							<CustomAlert>
								<h1>Send beskjed til kontroll</h1>
								<p>Vil du sende beskjeden til kontroll?</p>
								<div>
									<strong>Fra:</strong>
									<p>{beskjed.fra}</p>
									<strong>Til:</strong>
									<p>
										{ansatt.fornavn} {ansatt.etternavn}
									</p>
									<strong>Bedrift:</strong>
									<p>{bedrift.firmaNavn}</p>
									<strong>Beskjed:</strong>
									<p>{beskjed.beskjed}</p>
									<strong>Skjult info:</strong>
									<p>{beskjed.skjultInfo}</p>
								</div>
								<button onClick={onClose}>
									Nei, jeg vil endre
								</button>
								<button
									onClick={async () => {
										await axios
											.post(
												`${BACKEND_URL}createBeskjed`,
												{
													beskjed: {
														...beskjed,
														tid: new Date(),
													},
												},
												{
													headers: {
														authorization: `Bearer ${await authService.getAccessToken()}`,
													},
												}
											)
											.then((res) => {
												if (res.status === 200) {
													setVisible(true);
													clearAllInputs();
													onClose();
												}
											})
											.catch((err) => {
												console.log(err);
												if (
													err.response.status === 500
												) {
													setSkrevetTilVisible(true);
													onClose();
												}
											});
									}}>
									Ja!
								</button>
							</CustomAlert>
						);
					},
				});
			}
		}
	};

	const handleChange = (e) => {
		if (e.target.type === 'checkbox') {
			if (e.target.name === 'sattOver' && e.target.checked) {
				setBeforeSattOver(beskjed.beskjed);

				let tmpBeskjed = beskjed.beskjed;

				while (tmpBeskjed[tmpBeskjed.length - 1] === '.') {
					tmpBeskjed = tmpBeskjed.substring(0, tmpBeskjed.length - 1);
				}

				dispatch(
					updateBeskjed({
						[e.target.name]: e.target.checked,
						stoppDenne: true,
						beskjed:
							tmpBeskjed +
							(tmpBeskjed === ''
								? 'Samtalen ble satt over...'
								: '. Samtalen ble satt over...'),
					})
				);
			} else if (e.target.name === 'sattOver' && !e.target.checked) {
				dispatch(
					updateBeskjed({
						[e.target.name]: e.target.checked,
						stoppDenne: false,
						beskjed: beforeSattOver,
					})
				);
			} else {
				dispatch(updateBeskjed({[e.target.name]: e.target.checked}));
			}
		} else {
			dispatch(updateBeskjed({[e.target.name]: e.target.value}));
		}
	};

	const toggleAlarm = () => {
		setVisible(!visible);
	};

	const toggleSkrevetTil = () => {
		setSkrevetTilVisible(!skrevetTilVisible);
	};

	const forkastClick = () => {
		confirmAlert({
			title: 'Forkast beskjeden',
			message: 'Sikker på at du vil forkaste beskjeden?',
			buttons: [
				{
					label: 'Ja',
					onClick: () => {
						clearAllInputs();
					},
				},
				{
					label: 'Nei',
					onClick: () => {},
				},
			],
		});
	};

	return (
		<Wrapper>
			<Alarm color='success' isOpen={visible} toggle={toggleAlarm}>
				Beskjed sendt til kontoll
			</Alarm>
			<Alarm
				color='danger'
				isOpen={skrevetTilVisible}
				toggle={toggleSkrevetTil}>
				Ingen kontakt med databasen. Beskjed blir forsøkt skrevet
				senere.
			</Alarm>
			<Beskjed>
				<BeskjedHead style={{fontSize: '1.5em'}}>Beskjed: </BeskjedHead>
				<Form
					onSubmit={(e) => {
						send(e);
					}}>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='fra'>Fra</Label>
								<Input
									type='text'
									value={beskjed.fra}
									onChange={handleChange}
									name='fra'
									id='beskjedFra'
									placeholder='Navn på innringer'
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='fraTelefon'>Fra telefon</Label>
								<Input
									type='tel'
									value={beskjed.fraTelefon}
									onChange={handleChange}
									name='fraTelefon'
									id='beskjedFraTelefon'
									placeholder='Telefon til innringer'
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='beskjed'>Beskjed</Label>
								<Input
									type='textarea'
									value={beskjed.beskjed}
									onChange={handleChange}
									name='beskjed'
									id='beskjedBeskjed'
									placeholder='Beskjed'
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<CustomInput
									type='switch'
									id='beskjedStoppDenne'
									name='stoppDenne'
									label='Bare sluttrapport (stopp denne)'
									inline
									checked={beskjed.stoppDenne}
									onChange={handleChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedFireMinutter'
									name='fireMinutter'
									label='Over 4 minutter'
									inline
									checked={beskjed.fireMinutter}
									onChange={handleChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedSattOver'
									name='sattOver'
									label='Satt over'
									inline
									checked={beskjed.sattOver}
									onChange={handleChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedHaster'
									name='haster'
									label='Haster'
									inline
									checked={beskjed.haster}
									onChange={handleChange}
								/>
								<CustomInput
									type='switch'
									id='beskjedVent'
									name='vent'
									label='Vent'
									inline
									checked={beskjed.vent}
									onChange={handleChange}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Label for='skjultInfo'>Internmelding</Label>
								<Input
									type='textarea'
									value={beskjed.skjultInfo}
									onChange={handleChange}
									name='skjultInfo'
									id='beskjedSkultInfo'
									placeholder='Internmelding'
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={12}>
							<FormGroup>
								<Button color='success'>OK</Button>
							</FormGroup>
						</Col>
					</Row>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Button color='danger' onClick={forkastClick}>
									Forkast beskjed
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</Beskjed>

			<AlleskontrollWrapper>
				<button
					onClick={() => {
						setAllesKontrollVisible(!alleskontrollVisible);
					}}>
					{alleskontrollVisible ? 'Skjul ' : 'Vis '}alleskontroll
				</button>
				{alleskontrollVisible && <Alleskontroll />}
			</AlleskontrollWrapper>
		</Wrapper>
	);
};

export default Messagefield;
