/** @format */

export const updateBedrift = (value) => {
	return {
		type: 'UPDATE_BEDRIFT',
		payload: value,
	};
};

export const updateBedriftSet = (value) => {
	console.log('VALUE: ' + value);
	return {
		type: 'UPDATE_BEDRIFT_SET',
		payload: value,
	};
};

export const setBedriftDefault = () => {
	return {
		type: 'SET_BEDRIFT_DEFAULT',
	};
};
