/** @format */

// React imports
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';

// Redux imports
import {updateBedriftSet} from '../../actions/chosenBedrift.js';
import {updateAnsattSet} from '../../actions/ansattActions.js';

// CSS
import './Bedriftinfo.css';

// Constants
const Wrapper = styled.div`
	padding: 1%;
	width: 100%;
	display: grid;
	grid-auto-rows: minmax(min-content, max-content);
	grid-auto-columns: 1fr 1fr;
`;

const ValgtInfo = styled.div`
	grid-column: 1;
	grid-row: 2/2;
	margin-top: 5%;
	margin-bottom: 5%;
	padding: 2%;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	text-align: center;
	align-items: center;
	display: grid;
	grid-auto-rows: minmax(min-content, max-content);

	table {
		border-spacing: 0;
		tr {
			:last-child {
				td {
					border-bottom: 0;
				}
			}
		}

		th,
		td {
			margin: 0;
			padding: 0.5rem;
			:last-child {
				border-right: 0;
			}
		}
	}
`;

const InfoAnsatt = styled.div`
	justify-self: center;
`;

const Button = styled.button`
	grid-column: 1;
	grid-row: 2;
`;

const Info = styled.div`
	display: grid;
	width: 100%;
	grid-auto-rows: minmax(min-content, max-content);
`;

const BedriftInfo = ({sok}) => {
	const dispatch = useDispatch();
	const ansatt = useSelector((state) => state.ansatt);
	const bedrift = useSelector((state) => state.bedrift);

	const handleOnClick = () => {
		sok.current.select.clearValue();
		dispatch(updateBedriftSet(false));
		dispatch(updateAnsattSet(false));
	};

	return (
		<Wrapper>
			<Info>
				{bedrift.set && !ansatt.set && (
					<ValgtInfo>
						<h1>{bedrift.firmaNavn}</h1>
						<table>
							<tbody>
								<tr>
									<td>
										<strong>Adresse:</strong>
									</td>
									<td>{bedrift.adr1}</td>
								</tr>
								<tr>
									<td>
										<strong>Poststed: </strong>
									</td>
									<td>{bedrift.poststed}</td>
								</tr>
								<tr>
									<td>
										<strong>Telefon:</strong>
									</td>
									<td>{bedrift.telefon}</td>
								</tr>
								<tr>
									<td>
										<strong>Firmaepost:</strong>
									</td>
									<td>{bedrift.epost}</td>
								</tr>
								<tr>
									<td>
										<strong>Firmaregisteret:</strong>
									</td>
									<td>{bedrift.regNrFirmaregisteret}</td>
								</tr>
								<tr>
									<td>
										<strong>Web:</strong>
									</td>
									<td>
										<a href={'https://' + bedrift.varWeb}>
											{bedrift.varWeb}
										</a>
									</td>
								</tr>
							</tbody>
						</table>
						<h3>Info</h3>
						<div>{bedrift.info1}</div>
					</ValgtInfo>
				)}
				{bedrift.set && ansatt.set && (
					<ValgtInfo>
						<h1>
							{ansatt.fornavn} {ansatt.etternavn}
						</h1>
						<table>
							<tbody>
								<tr>
									<td>
										<strong>Telefon:</strong>
									</td>
									<td>{ansatt.telefon1}</td>
								</tr>
								<tr>
									<td>
										<strong>Epost:</strong>
									</td>
									<td>{ansatt.epost}</td>
								</tr>
								<tr>
									<td>
										<strong>Nøkkel:</strong>
									</td>
									<td>{ansatt.nokkelStikkord}</td>
								</tr>
								<tr>
									<td>
										<strong>Stilling: </strong>
									</td>
									<td>{ansatt.stilling}</td>
								</tr>
								<tr>
									<td>
										<strong>Trio:</strong>
									</td>
									<td>{ansatt.trioKode}</td>
								</tr>
							</tbody>
						</table>
						<h3>Info</h3>
						<div>{ansatt.info1}</div>
						<InfoAnsatt></InfoAnsatt>
					</ValgtInfo>
				)}
			</Info>

			<Button onClick={handleOnClick}>Tilbake til Alleskontroll</Button>
		</Wrapper>
	);
};

export default BedriftInfo;
