/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';

// Component imports
import {Form, Row, Col, FormGroup, Button, Label, Input} from 'reactstrap';
import Select from 'react-select';

// Helpers
import {BACKEND_URL} from '../../misc/variables.js';

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	width: 95%;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	grid-row: 2/2;
`;

const Sekreter1 = styled(Select)`
	width: 100%;
`;

const EndreSekreter = () => {
	const defaultSekreter = {
		fornavn: '',
		etternavn: '',
		telefon: '',
		privatEpost: '',
		firmaEpost: '',
		ansvarFor: '',
	};
	const [sekreter, setSekreter] = useState(defaultSekreter);
	const [chosenSekreter, setChosenSekreter] = useState(false);
	const [sekreterer, setSekreterer] = useState([]);
	const {authService} = useOktaAuth();
	const [ansvarlige, setAnsvarlige] = useState([]);
	const [values, setValues] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getSekreterer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getSekreterer = async () => {
		await axios
			.get(`${BACKEND_URL}getAlleSekreterer`, {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then((res) => {
				if (res.status === 200) {
					setSekreterer(res.data);
					formatAndSetValues(res.data);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const formatAndSetValues = (data) => {
		setValues(
			data.map((s) => ({
				label: `${s.fornavn} ${s.etternavn}`,
				value: s.sekreterID,
			}))
		);

		setAnsvarlige(
			data.map((s) => ({
				label: (
					<div>
						{s.fornavn} {s.etternavn}
						<span
							style={{
								fontSize: '90%',
								display: 'block',
								marginTop: '-.4em',
							}}>
							Ansvarlig for{' '}
							{s.ansvarligFor === null ? 'ingen' : s.ansvarligFor}
						</span>{' '}
					</div>
				),
				value: s.sekreterID,
			}))
		);
	};

	const handleSekreter1Change = async (e, {action}) => {
		switch (action) {
			case 'clear':
				setSekreter(defaultSekreter);
				setChosenSekreter(false);
				break;
			default:
				setSekreter(
					sekreterer.filter((s) => s.sekreterID === e.value)[0]
				);
				setChosenSekreter(true);
				break;
		}
	};

	const handleAnsvarligChange = async (e, {action}) => {
		switch (action) {
			case 'clear':
				setSekreter({...sekreter, ansvarligForID: ''});
				break;
			default:
				setSekreter({...sekreter, ansvarligForID: e.value});
				break;
		}
	};

	const handleButtonClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		await axios
			.put(
				`${BACKEND_URL}endreSekreter`,
				{
					sekreter: sekreter,
					origSekreter: sekreterer.filter(
						(s) => s.sekreterID === sekreter.sekreterID
					)[0],
				},
				{
					headers: {
						authorization: `Bearer ${await authService.getAccessToken()}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 200) {
					alert(`Endret ${res.data} sekretær`);
				}
				setLoading(false);
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const handleDeleteClick = async (e) => {
		e.preventDefault();
		setLoading(true);
		await axios
			.delete(`${BACKEND_URL}slettSekterer`, {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
				data: sekreter,
			})
			.then((res) => {
				if (res.status === 200) {
					alert(`Slettet sekretær`);
					setSekreterer(
						sekreterer.filter(
							(s) => s.sekreterID !== sekreter.sekreterID
						)
					);
					setAnsvarlige(
						ansvarlige.filter(
							(s) => s.value !== sekreter.sekreterID
						)
					);

					setValues(
						values.filter((s) => s.value !== sekreter.sekreterID)
					);
					setSekreter(defaultSekreter);
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
				console.log(err);
			});
	};

	const handleFormChange = (e) => {
		setSekreter({...sekreter, [e.target.name]: e.target.value});
	};

	return (
		<Wrapper>
			<Sekreter1
				isClearable
				isSearchable
				options={values}
				onChange={handleSekreter1Change}
				placeholder='Velg sekretær'
			/>
			{chosenSekreter && (
				<Form
					style={{
						border: '2px solid #2d93ad',
						margin: '10px 0 0 0',
						padding: '5px 5px 5px 5px',
					}}>
					<Row form>
						<Col md={6}>
							<FormGroup>
								<Label for='fornavn'>Fornavn</Label>
								<Input
									type='text'
									id='fornavn'
									name='fornavn'
									placeholder='Fornavn'
									value={sekreter.fornavn}
									onChange={handleFormChange}
									disabled={loading}
								/>
							</FormGroup>
						</Col>
						<Col md={6}>
							<FormGroup>
								<Label for='etternavn'>Etternavn</Label>
								<Input
									type='text'
									id='etternavn'
									name='etternavn'
									placeholder='Etternavn'
									value={sekreter.etternavn}
									onChange={handleFormChange}
									disabled={loading}
								/>
							</FormGroup>
						</Col>
					</Row>

					<Row form>
						<Col md={2}>
							<FormGroup>
								<Label for='telefon'>Telefon</Label>
								<Input
									type='mobile'
									id='telefon'
									name='telefon'
									placeholder='Telefon'
									value={sekreter.telefon}
									onChange={handleFormChange}
									disabled={loading}
								/>
							</FormGroup>
						</Col>
						<Col md={10}>
							<FormGroup>
								<Label for='ansvarligFor'>Ansvarlig for</Label>
								<Sekreter1
									name='ansvarligFor'
									id='ansvarligFor'
									isSearchable
									isClearable
									value={ansvarlige.filter(
										(s) =>
											s.value === sekreter.ansvarligForID
									)}
									options={ansvarlige.filter(
										(s) => s.value !== sekreter.sekreterID
									)}
									onChange={handleAnsvarligChange}
									isDisabled={loading}
								/>
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col>
							<FormGroup>
								<Button
									onClick={handleButtonClick}
									disabled={loading}
									color='success'>
									Endre skretær
								</Button>
							</FormGroup>
						</Col>
						<Col>
							<FormGroup>
								<div className='text-right'>
									<Button
										color='danger'
										disabled={loading}
										onClick={handleDeleteClick}>
										Slett skretær
									</Button>
								</div>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			)}
		</Wrapper>
	);
};

export default EndreSekreter;
