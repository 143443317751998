/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	font-size: 2vw;
`;

function Clock() {
	const [time, setTime] = useState(new Date().toLocaleTimeString('nn-NO'));

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date().toLocaleTimeString('nn-NO'));
		}, 100);
		return () => clearInterval(interval);
	}, []);

	return <Wrapper>{time}</Wrapper>;
}

export default Clock;
