/** @format */

// React imports
import React, {useState, useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useInterval} from '../Hooks/Hooks.js';
import styled from 'styled-components';
import {useActiveElement} from '../Hooks/Hooks';
import {BACKEND_URL} from '../../misc/variables.js';
import {toast} from 'react-toastify';
import {useOktaAuth} from '@okta/okta-react';

// Helpers
import {sendEmailHelper, sendSmsHelper} from '../../misc/helpers.js';

// CSS
import 'react-toastify/dist/ReactToastify.css';

// Component imports
import {
	Col,
	Row,
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	Badge,
} from 'reactstrap';

// Configure toast
toast.configure();

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	align-items: left;
	margin-top: 5em;
	padding: 1%;
`;

const AllesKontollHead = styled.div`
	font-size: 1.5em;
`;

const Br = styled.span`
	display: block;
	margin-bottom: 2.3em;
`;

const BrLarge = styled.span`
	display: block;
	margin-bottom: 3em;
`;

const Alleskontroll = () => {
	const [beskjeder, setBeskjeder] = useState('');
	const focusedElement = useActiveElement();
	const defaultBeskjed = {
		beskjedID: '',
		bedriftID: '',
		ansattID: '',
		fra: '',
		fraTelefon: '',
		beskjed: '',
		skjultInfo: '',
		tid: '',
		stoppDenne: false,
		kunStatistikk: false,
		fireMinutter: false,
		sattOver: false,
		haster: false,
		vent: false,
		godkjent: false,
		sekreterID: '',
		fornavn: '',
		etternavn: '',
		fullname: '',
		firmaNavn: '',
		beskjedMail: '',
		beskjedSms: '',
		epost: '',
		telefon1: '',
		telefon2: '',
		kommentar: '',
		ansvarligSekreter: '',
	};

	const [beskjed, setBeskjed] = useState(defaultBeskjed);
	const sekreter = useSelector((state) => state.sekreter);

	const {authService, authState} = useOktaAuth();
	const [vent, setVent] = useState(false);

	const beskjedInp = useRef(null);
	const skjultInfoInp = useRef(null);
	const formRef = useRef(null);

	useInterval(() => {
		getAndSetBeskjeder();
	}, 10000);

	const getAndSetBeskjeder = async () => {
		axios
			.get(BACKEND_URL + 'getAlleskontoll', {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then((res) => {
				if (res.status === 500) {
					console.log('Kunne ikke hente alleskontroller');
				} else if (
					res.data.length > 0 &&
					!(
						beskjedInp.current === focusedElement ||
						skjultInfoInp.current === focusedElement
					)
				) {
					setBeskjeder(res.data);
					setBeskjed(res.data[0]);
				} else if (res.data.length === 0) {
					setBeskjeder([]);
					setBeskjed(defaultBeskjed);
				}
				console.log('Hentet info');
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getAndSetBeskjeder();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleUnderkjent = async () => {
		if (beskjed.sekreterID === sekreter.sekreterID && !beskjed.vent) {
			toast.error('Du kan ikke underkjenne din egen beskjed', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			setBeskjed({...beskjed, retting: 1});
			await axios
				.post(
					`${BACKEND_URL}alterBeskjed`,
					{
						...beskjed,
						retting:
							beskjed.vent && !vent
								? 0
								: beskjed.vent && vent
								? 0
								: 1,
					},
					{
						headers: {
							authorization: `Bearer ${authState.accessToken}`,
						},
					}
				)
				.then((res) => {
					if (!beskjed.vent) {
						toast.success('Beskjed sendt til retting', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						moveToNextBeskjed();
					} else {
						toast.success('Lagret!', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
					console.log(res);
				})
				.catch((err) => {
					toast.error('Noe feil skjedde', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					console.log(err);
				});
		}
	};

	const send = async () => {
		let success;
		let melding;
		await axios
			.get(`${BACKEND_URL}getSekreterFromID/${beskjed.sekreterID}`, {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then(async (res) => {
				if (beskjed.beskjedMail && beskjed.beskjedSms) {
					success =
						(await sendEmailHelper(
							await authService.getAccessToken(),
							beskjed,
							res.data.sekreter
						)) &&
						(await sendSmsHelper(
							await authService.getAccessToken(),
							beskjed
						));
					melding = 'Email og Sms er sendt!';
				} else if (beskjed.beskjedMail) {
					success = await sendEmailHelper(
						await authService.getAccessToken(),
						beskjed,
						res.data.sekreter
					);
					melding = 'Email er sendt!';
				} else if (beskjed.beskjedSms) {
					success = await sendSmsHelper(
						await authService.getAccessToken(),
						beskjed
					);
					melding = 'Sms er sendt!';
				} else {
					melding = `Valgt ansatt har ikke registert noen meldingspreferanse. Beskjed ikke sendt.`;
					success = false;
				}
			});

		if (success) {
			await axios
				.post(
					BACKEND_URL + 'alterBeskjed',
					{
						beskjedID: beskjed.beskjedID,
						beskjed: beskjed.beskjed,
						skjultInfo: beskjed.skjultInfo,
						stoppDenne: beskjed.stoppDenne,
						kunStatistikk: beskjed.kunStatistikk,
						fireMinutter: beskjed.fireMinutter,
						sattOver: beskjed.sattOver,
						haster: beskjed.haster,
						vent: beskjed.vent,
						egenkontroll: true,
						godkjent: true,
						retting: false,
						godkjentAv: sekreter.sekreterID,
					},
					{
						headers: {
							authorization: `Bearer ${await authService.getAccessToken()}`,
						},
					}
				)
				.then((res) => {
					toast.success(melding, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					moveToNextBeskjed();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			toast.info(
				`Valgt ansatt har ikke registert noen meldingspreferanse. Beskjed ikke sendt.`,
				{
					position: 'top-right',
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);
		}
	};

	const moveToNextBeskjed = () => {
		let tempBeskjeder = beskjeder.filter(
			(b) => b.beskjedID !== beskjed.beskjedID
		);
		if (tempBeskjeder.length === 0) {
			setBeskjeder([]);
			setBeskjed(defaultBeskjed);
		} else {
			setBeskjeder(tempBeskjeder);
			setVent(tempBeskjeder[0].vent);
			setBeskjed(tempBeskjeder[0]);
		}
	};

	const handleGodkjent = async () => {
		if (beskjed.vent) {
			toast.info('Du må fjerne vent før du gjør dette', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			send();
		}
	};

	const handleChange = (e) => {
		e.target.type === 'checkbox'
			? setBeskjed({
					...beskjed,
					[e.target.name]: e.target.checked,
			  })
			: setBeskjed({...beskjed, [e.target.name]: e.target.value});
	};

	const next = () => {
		if (beskjeder.length > 1) {
			const first = beskjeder.shift();
			setBeskjeder(beskjeder);
			setBeskjed(beskjeder[0]);
			let tempBeskjeder = [...beskjeder, first];
			setBeskjeder(tempBeskjeder);
		}
	};

	const prev = () => {
		if (beskjeder.length > 1) {
			const last = beskjeder.pop();
			setBeskjeder(beskjeder);
			setBeskjed(last);
			let tempBeskjeder = [last, ...beskjeder];
			setBeskjeder(tempBeskjeder);
		}
	};

	return (
		<Wrapper>
			<AllesKontollHead>
				Alleskontroll{'   '}
				{beskjeder.length === 0 ? (
					<Badge color='secondary'>0</Badge>
				) : (
					<Badge color='success'>
						{beskjed.counter} / {beskjeder.length}
					</Badge>
				)}
				:
			</AllesKontollHead>
			<Form innerRef={formRef}>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='bedrift'>Bedrift</Label>
							<Input
								value={beskjed.firmaNavn}
								disabled
								type='text'
								placeholder='Bedrift'
								name='bedrift'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.stoppDenne}
								name='stoppDenne'
								id='alleskontrollStoppDenne'
								label='Bare sluttrapport (stopp denne)'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='ansatt'>Kontakt i bedrift</Label>
							<Input
								value={beskjed.fullname}
								disabled
								type='text'
								placeholder='Kontakt i bedrift'
								name='ansatt'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.fireMinutter}
								name='fireMinutter'
								id='alleskontrollFireMinutter'
								label='4 Minutter'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='fra'>Fra</Label>
							<Input
								value={beskjed.fra}
								disabled
								type='text'
								placeholder='Fra'
								name='fra'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.sattOver}
								name='sattOver'
								id='alleskontrollSattOver'
								label='Satt over'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='fraTelefon'>Fra telefon</Label>
							<Input
								value={beskjed.fraTelefon}
								disabled
								type='text'
								placeholder='Fra telefon'
								name='fraTelefon'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<BrLarge />
							<CustomInput
								type='switch'
								checked={beskjed.haster}
								name='haster'
								id='alleskontrollHaster'
								label='Haster'
								innerRef={beskjedInp}
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='beskjed'>Beskjed</Label>
							<Input
								value={beskjed.beskjed}
								type='textarea'
								placeholder='Beskjed'
								onChange={handleChange}
								name='beskjed'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<BrLarge />
							<CustomInput
								type='switch'
								checked={beskjed.vent}
								name='vent'
								id='alleskontrollVent'
								label='Vent'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='skjultInfo'>Internmelding</Label>
							<Input
								value={beskjed.skjultInfo}
								type='textarea'
								placeholder='Internmelding'
								name='skjultInfo'
								onChange={handleChange}
								innerRef={skjultInfoInp}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<FormGroup>
							<Button onClick={prev}>Forrige</Button>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Button onClick={next}>Neste</Button>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<FormGroup>
							<Button onClick={handleUnderkjent} color='danger'>
								Underkjenn
							</Button>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Button onClick={handleGodkjent} color='success'>
								Godkjenn
							</Button>
						</FormGroup>
					</Col>
				</Row>
			</Form>
		</Wrapper>
	);
};

export default Alleskontroll;
