/** @format */

// React imports
import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';

// Helpers imports
import {BACKEND_URL} from '../../misc/variables.js';

// Redux imports
import {useDispatch, useSelector} from 'react-redux';
import {updateBeskjed, setDefaultBeskjed} from '../../actions/beskjed';
import {
	updateBedrift,
	updateBedriftSet,
	setBedriftDefault,
} from '../../actions/chosenBedrift.js';

import {
	updateAnsatt,
	updateAnsattSet,
	setDefaultAnsatt,
} from '../../actions/ansattActions.js';

// Component imports
import BedriftInfo from '../BedriftInfo/BedriftInfo.jsx';
import Select from 'react-select';
import Egenkontroll from '../Egenkontroll/Egenkontroll.jsx';
import {Badge} from 'reactstrap';

// Constants
const axios = require('axios');
const {processAnsatte} = require('../../misc/helpers.js');

const Wrapper = styled.div`
	margin-top: 1vh;
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
`;

const SokBedrift = styled(Select)`
	grid-row: 1/1;
	width: 100%;
	margin-bottom: 2%;
`;

const SokAnsatt = styled(Select)`
	grid-row: 2/2;
`;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	fontWeigth: 'bold',
};

const Bedriftsok = ({
	setSok,
	setBedriftInfo,
	ansatte,
	setAnsatte,
	loggedIn,
}) => {
	const dispatch = useDispatch();
	const sekreter = useSelector((state) => state.sekreter);
	const [bedrifter, setBedrifter] = useState();
	const chosenBedrift = useSelector((state) => state.bedrift.set);
	const [values, setValues] = useState([]);
	const {authService} = useOktaAuth();
	const [ansatteWithGroups, setAnsatteWithGroups] = useState([]);
	const [ansatteWithoutGroups, setAnsatteWithoutGroups] = useState([]);
	const bedriftInp = useRef(null);
	const ansattInp = useRef(null);

	const getBedrifter = async () => {
		await axios
			.get(BACKEND_URL + 'getBedrifter', {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then((res) => {
				if (res === 'No connection') {
					console.log('Ikke kontakt med databasen');
				} else {
					let temp = [];
					for (let i = 0; i < res.data.length; i++) {
						temp.push({
							label: res.data[i].firmaNavn,
							value: res.data[i].bedriftID.toString(),
						});
					}
					setBedrifter(temp);
					setBedriftInfo(res.data);
				}
			})
			.catch((err) => {
				if (err.status === 500) {
					console.log('Får ikke kontakt med databasen');
				}
				console.log(err);
			});
	};

	useEffect(() => {
		setSok(bedriftInp);
	}, [bedriftInp, setSok]);

	useEffect(() => {
		getBedrifter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleBedriftChange = async (e, {action}) => {
		dispatch(updateAnsattSet(false));
		if (ansattInp.current !== null) {
			ansattInp.current.select.clearValue();
		}
		switch (action) {
			case 'clear':
				dispatch(updateBedriftSet(false));
				dispatch(setBedriftDefault());
				dispatch(setDefaultBeskjed());
				dispatch(updateBeskjed({sekreterID: sekreter.sekreterID}));
				dispatch(setDefaultAnsatt());
				break;
			default:
				axios
					.get(`${BACKEND_URL}getBedrift/${e.value}`, {
						headers: {
							authorization: `Bearer ${await authService.getAccessToken()}`,
						},
					})
					.then((res) => {
						if (res.data === 'No connection') {
							alert(
								'Får ikke kontakt med databasen, har du internetttilkobling?'
							);
						} else {
							dispatch(updateBedrift(res.data));
							generateAnsatte(parseInt(e.value));
							dispatch(updateBeskjed({bedriftID: e.value}));
							dispatch(updateBedriftSet(true));
						}
					})
					.catch((err) => {
						if (err.response.status === 500) {
							console.log('Får ikke kontakt med databasen');
						}
						console.log(err);
					});
		}
	};

	const handleAnsattChange = async (e, {action}) => {
		switch (action) {
			case 'clear':
				dispatch(updateBeskjed({ansattID: ''}));
				//setAnsatt(defaultAnsatt);
				dispatch(setDefaultAnsatt());
				dispatch(updateAnsattSet(false));
				break;
			default:
				dispatch(
					updateAnsatt(
						ansatte.filter(
							(a) => a.ansattID === parseInt(e.value)
						)[0]
					)
				);
				dispatch(updateBeskjed({ansattID: parseInt(e.value)}));
				dispatch(updateAnsattSet(true));
		}
	};

	const generateAnsatte = async (id) => {
		await new Promise(async (resolve, reject) =>
			resolve(processAnsatte(id, await authService.getAccessToken()))
		).then((res) => {
			setAnsatteWithoutGroups(res[0]);
			setAnsatteWithGroups(res[1]);
			setValues(res[1]);
			setAnsatte(res[2]);
		});
	};

	const filterOptions = (candidate, value) => {
		if (value === '') {
			setValues(ansatteWithGroups);
			return true;
		} else {
			setValues(ansatteWithoutGroups);
		}
		if (
			candidate.label
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.value
				.toString()
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.data.sokeOrd
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim())
		)
			return true;
	};

	const formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<Badge color='info' pill>
				{data.label}
			</Badge>
			<Badge color='success'>{data.options.length}</Badge>
		</div>
	);

	return (
		<Wrapper>
			<SokBedrift
				isClearable
				isSearchable
				ref={bedriftInp}
				options={bedrifter}
				onChange={handleBedriftChange}
				placeholder='Velg bedrift'
			/>
			{chosenBedrift ? (
				<div>
					<SokAnsatt
						ref={ansattInp}
						isClearable
						isSearchable
						options={values}
						filterOption={filterOptions}
						onChange={handleAnsattChange}
						formatGroupLabel={formatGroupLabel}
						noOptionsMessage={() => {
							return 'Fant ingen ansatte';
						}}
						placeholder='Velg ansatt'
					/>
					<BedriftInfo
						sok={bedriftInp}
						ansatte={ansatte}
						values={values}
						setAnsatte={(ansatte) => {
							setAnsatte(ansatte);
						}}
					/>
				</div>
			) : (
				<Egenkontroll loggedIn={loggedIn} />
			)}
		</Wrapper>
	);
};

export default Bedriftsok;
