/** @format */

// React imports
import React, {useState} from 'react';
import OktaAuth from '@okta/okta-auth-js';
import {useOktaAuth} from '@okta/okta-react';
import styled from 'styled-components';

// Constants
const Wrapper = styled.div`
	display: grid;
	grid-auto-rows: 1fr 1fr 1fr;
	margin: auto;
	width: 50%;
	top: 50%;
	padding: 10px;
`;

const Input = styled.input.attrs((props) => ({
	// we can define static props
	// or we can define dynamic ones
	size: props.size || '1em',
}))`
	color: black;
	font-size: 1em;
	border: 2px solid #2D93AD;
	border-radius: 3px;
	/* here we use the dynamically computed prop */
	/* margin: ${(props) => props.size}; */
	padding: ${(props) => props.size};
	width: ${(props) => (props.width ? props.width : 'auto')};
`;

const Button = styled.button`
	/* Adapt the colors based on primary prop */
	background: ${(props) => (props.primary ? '#2D93AD' : 'white')};
	color: ${(props) => (props.primary ? 'white' : 'black')};

	font-size: 1em;
	margin: 1em;
	padding: 0.25em 1em;
	border: 2px solid #2d93ad;
	border-radius: 3px;
	grid-row-start: 6;
	width: ${(props) => (props.width ? props.width : 'auto')};
`;

const Username = styled(Input)`
	grid-row: 2/2;
`;

const Password = styled(Input)`
	grid-row: 3/3;
`;

const LoginBtn = styled(Button)`
	grid-row: 4/4;
	cursor: pointer;
`;

const Head = styled.h1`
	grid-row: 1/1;
`;

const LoginForm = ({issuer}) => {
	const {authService} = useOktaAuth();
	const [sessionToken, setSessionToken] = useState();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = (e) => {
		e.preventDefault();
		const oktaAuth = new OktaAuth({issuer: issuer});
		oktaAuth
			.signIn({username, password})
			.then(async (res) => {
				const sessionToken = res.sessionToken;
				setSessionToken(sessionToken);
				// sessionToken is a one-use token, so make sure this is only called once
				authService.redirect({sessionToken});
			})
			.catch((err) => {
				console.log('Found an error', err);
				if (err.errorCode === 'E0000004') {
					alert('Feilet med å logge inn.');
				}
			});
	};

	const handleUsernameChange = (e) => {
		setUsername(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	if (sessionToken) {
		// Hide form while sessionToken is converted into id/access tokens
		return null;
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			document.getElementById('loginBtn').click();
		}
	};

	return (
		<Wrapper onKeyPress={handleKeyDown}>
			<Head>Velkommen til Franke Sjøberg!</Head>
			<Username
				type='email'
				placeholder='Brukernavn'
				onChange={handleUsernameChange}
				value={username}
			/>
			<Password
				type='password'
				placeholder='Passord'
				value={password}
				onChange={handlePasswordChange}
			/>
			<LoginBtn id='loginBtn' onClick={handleSubmit}>
				Login
			</LoginBtn>
		</Wrapper>
	);
};
export default LoginForm;
