/** @format */

import {combineReducers} from 'redux';

// Reducers
import sektreterReduser from './sekreter';
import userInfoReduser from './userInfo.js';
import beskjedReduser from './beskjed';
import alleskontrollReduser from './alleskontrollBeskjed.js';
import bedriftReduser from './bedriftReduser.js';
import ansattReduser from './ansattReduser.js';

const allReducers = combineReducers({
	sekreter: sektreterReduser,
	userInfo: userInfoReduser,
	beskjed: beskjedReduser,
	alleskontrollBeskjed: alleskontrollReduser,
	bedrift: bedriftReduser,
	ansatt: ansattReduser,
});

export default allReducers;
