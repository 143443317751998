/** @format */

const userInfoReduser = (
	state = {
		name: '',
		groups: [],
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_USER_INFO':
			return action.payload;
		case 'SET_USER_INFO_NONE':
			return {};
		default:
			return state;
	}
};

export default userInfoReduser;
