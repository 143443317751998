/** @format */

import React, {useState, useMemo, useCallback} from 'react';
import styled from 'styled-components';
import {useDropzone} from 'react-dropzone';
import {useOktaAuth} from '@okta/okta-react';

// Component imports
import {Button} from 'reactstrap';

//Misc inmport
import {BACKEND_URL} from '../../misc/variables.js';
// Constants
const axios = require('axios');

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
	justify-self: left;
	align-self: left;
	grid-row: 2/2;
`;

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

const Remove = styled.button`
	border-radius: 12px;
	background-color: #bb2124;
	color: white;
	font-size: 0.8em;
`;

const Li = styled.li`
	margin: 10px 0;
`;

const ImportBedrifter = () => {
	const {authService} = useOktaAuth();
	const [myFiles, setMyFiles] = useState([]);

	const onDrop = useCallback(
		(acceptedFiles) => {
			setMyFiles([...myFiles, ...acceptedFiles]);
		},
		[myFiles]
	);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		accept:
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});

	const removeFile = (file) => () => {
		const newFiles = [...myFiles];
		newFiles.splice(newFiles.indexOf(file), 1);
		setMyFiles(newFiles);
	};

	const removeAll = () => {
		setMyFiles([]);
	};

	const files = myFiles.map((file) => (
		<Li key={file.path}>
			{file.path} - {file.size} bytes{' '}
			<Remove onClick={removeFile(file)}>Fjern fil</Remove>
		</Li>
	));

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	const handleSubmit = async () => {
		myFiles.forEach(async (file) => {
			const formData = new FormData();
			formData.append(file.name, file);

			await axios
				.post(`${BACKEND_URL}uploadBedriftFile`, formData, {
					headers: {
						authorization: `Bearer ${await authService.getAccessToken()}`,
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => {
					if (res.status === 200) alert('Importerte bedrift(er)!');
				})
				.catch((err) => {
					if (err.response.status === 409) {
						alert(err.response.data);
					}
					console.log(err);
				});
		});
	};

	return (
		<Wrapper>
			<div className='container'>
				<div {...getRootProps({style, className: 'dropzone'})}>
					<input {...getInputProps()} />
					<p>Last opp eller dra excel-filer med bedriftinfo hit.</p>
				</div>
				<aside>
					<h4>Opplastede filer:</h4>
					<ul>{files}</ul>
				</aside>
				<Button onClick={handleSubmit}>Last opp</Button>
				{files.length > 0 && (
					<Button onClick={removeAll}>Fjern alle filer</Button>
				)}
			</div>
		</Wrapper>
	);
};

export default ImportBedrifter;
