/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';

// Component imports
import {
	Form,
	Button,
	FormGroup,
	Row,
	Col,
	Label,
	Input,
	Badge,
} from 'reactstrap';
import Select from 'react-select';

// Helpers
import {processAnsatte} from '../../misc/helpers';
import {BACKEND_URL} from '../../misc/variables.js';

// Constants
const axios = require('axios');

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	/*
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
    grid-auto-columns: (auto-fit, minmax(min-content, max-content));
    */
	grid-template-columns: 2fr 1fr 1fr 2fr;
	grid-template-areas:
		'. sokbedrift .'
		'. sokansatt .'
		'. form .';
	grid-row: 2/2;
`;

const SokBedrift = styled(Select)`
	grid-area: sokbedrift;
`;

const SokAnsatt = styled(Select)`
	grid-area: sokansatt;
`;

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	fontWeigth: 'bold',
};

const GenerateReports = () => {
	const [ansatteValues, setAnsatteValues] = useState([]);
	const [ansatteWithGroups, setAnsatteWithGroups] = useState([]);
	const [ansatteWithoutGroups, setAnsatteWithoutGroups] = useState([]);
	const {authService} = useOktaAuth();
	const [bedrifter, setBedrifter] = useState([]);
	const [formVals, setFormVals] = useState({
		bedrift: '',
		ansatt: '',
		fraDato: '',
		tilDato: '',
		ansattName: '',
		firmaName: '',
	});

	useEffect(() => {
		getBedrifter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBedrifter = async () => {
		await axios
			.get(BACKEND_URL + 'getBedrifter', {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then((res) => {
				if (res === 'No connection') {
					console.log('Ikke kontakt med databasen');
				} else {
					let temp = [];
					for (let i = 0; i < res.data.length; i++) {
						temp.push({
							label: res.data[i].firmaNavn,
							value: res.data[i].bedriftID.toString(),
						});
					}
					setBedrifter(temp);
				}
			})
			.catch((err) => {
				if (err.status === 500) {
					console.log('Får ikke kontakt med databasen');
				}
				console.log(err);
			});
	};

	const filterOptions = (candidate, value) => {
		if (value === '') {
			setAnsatteValues(ansatteWithGroups);
			return true;
		} else {
			setAnsatteValues(ansatteWithoutGroups);
		}
		if (
			candidate.label
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.value
				.toString()
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.data.sokeOrd
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim())
		)
			return true;
	};

	const formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<Badge color='info' pill>
				{data.label}
			</Badge>
			<Badge color='success'>{data.options.length}</Badge>
		</div>
	);

	const generateAnsatte = async (id) => {
		await new Promise(async (resolve, reject) =>
			resolve(processAnsatte(id, await authService.getAccessToken()))
		).then((res) => {
			setAnsatteWithoutGroups(res[0]);
			setAnsatteWithGroups(res[1]);
			setAnsatteValues(res[1]);
		});
	};

	const handleBedriftChange = (e, {action}) => {
		switch (action) {
			case 'clear':
				setFormVals({
					...formVals,
					ansatt: '',
					bedrift: '',
					ansattName: '',
					bedriftName: '',
				});
				break;
			default:
				generateAnsatte(parseInt(e.value));
				setFormVals({
					...formVals,
					bedrift: e.value,
					firmaName: e.label.replace(' ', '-'),
				});
		}
	};

	const handleAnsattChange = (e, {action}) => {
		switch (action) {
			case 'clear':
				setFormVals({...formVals, ansatt: '', ansattName: ''});
				break;
			default:
				setFormVals({
					...formVals,
					ansatt: e.value,
					ansattName: e.label.replace(' ', '-'),
				});
		}
	};

	const handleDatoChange = (e) => {
		setFormVals({...formVals, [e.target.name]: e.target.value});
	};

	const handleButtonClick = async (e) => {
		e.persist();
		if (formVals.bedrift === '') {
			alert('Du må velge en bedrift for dette');
		} else if (
			e.target.name.toLocaleLowerCase().includes('ansatt') &&
			formVals.ansatt === ''
		) {
			alert('Du må velge en ansatt!');
		} else if (formVals.fraDato > formVals.tilDato) {
			alert('Til dato kan ikke være større enn fra dato');
		} else {
			if (!e.target.name.toLocaleLowerCase().includes('send')) {
				const link = document.createElement('a');
				link.href =
					BACKEND_URL +
					`generateReport?bedrift=${formVals.bedrift}&ansatt=${
						formVals.ansatt
					}&fraDato=${formVals.fraDato}&tilDato=${
						formVals.tilDato
					}&ansattName=${formVals.ansattName}&firmaName=${
						formVals.firmaName
					}&type=${
						e.target.name
					}&token=${await authService.getAccessToken()}`;
				document.body.appendChild(link);

				link.click();
				document.body.removeChild(link);
			} else {
				await axios
					.get(`${BACKEND_URL}generateReport`, {
						params: {...formVals, type: e.target.name},
						headers: {
							authorization: `Bearer ${await authService.getAccessToken()}`,
						},
					})
					.then((res) => {
						if (res.status === 200) {
							alert('Sendt rapport!');
						} else if (res.status === 204) {
							alert('Ingen rapporter å sende');
						}
					})
					.catch((err) => {
						console.log(err);
					});
			}
		}
	};

	return (
		<Wrapper>
			<Form>
				<Row>
					<Col>
						<FormGroup>
							<Label for='bedrift'>Bedrift</Label>
							<SokBedrift
								name='bedrift'
								id='bedrift'
								isClearable
								isSearchable
								options={bedrifter}
								onChange={handleBedriftChange}
								placeholder='Velg bedrift'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col>
						<FormGroup>
							<Label for='ansatt'>Ansatt</Label>
							<SokAnsatt
								name='ansatt'
								id='ansatt'
								isClearable
								isSearchable
								options={ansatteValues}
								filterOption={filterOptions}
								onChange={handleAnsattChange}
								formatGroupLabel={formatGroupLabel}
								noOptionsMessage={() => {
									return 'Fant ingen ansatte';
								}}
								placeholder='Velg ansatt'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={6}>
						<FormGroup>
							<Label for='fraDato'>Fra dato</Label>
							<Input
								type='date'
								name='fraDato'
								id='fraDato'
								onChange={handleDatoChange}
								placeholder='Fra dato'
							/>
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for='tilDato'>Til og med dato</Label>
							<Input
								type='date'
								name='tilDato'
								id='tilDato'
								onChange={handleDatoChange}
								placeholder='Til og med dato'
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
						<Button name='bedrift' onClick={handleButtonClick}>
							Generer for bedrift
						</Button>
					</FormGroup>
					<FormGroup className='mb-2 mr-sm-2 mb-sm-0'>
						<Button name='ansatt' onClick={handleButtonClick}>
							Generer for ansatt
						</Button>
					</FormGroup>
				</Row>
				<Row>
					<FormGroup className='mr-sm-5 mt-sm-2'>
						<Button name='sendBedrift' onClick={handleButtonClick}>
							Send til bedrift
						</Button>
					</FormGroup>
					<FormGroup className='mr-sm-2 mt-sm-2'>
						<Button name='sendAnsatt' onClick={handleButtonClick}>
							Send til ansatt
						</Button>
					</FormGroup>
				</Row>
			</Form>
		</Wrapper>
	);
};

export default GenerateReports;
