/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import styled from 'styled-components';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';

// Redux imports
import {
	updateSekreter,
	setUserInfo,
	setUserInfoNone,
	setDefaultSekreter,
} from '../../actions';

// Helpers imports
import {BACKEND_URL} from '../../misc/variables.js';

// CSS imports
import 'react-toastify/dist/ReactToastify.css';

// Component imports
import MessageField from '../MessageField/MessageField.jsx';
import Bedriftsok from '../Bedriftsok/Bedriftsok';

// Configure toast
toast.configure();

// Constatnts

const axios = require('axios');

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 55% 45%;
	min-height: 100vh;
	justify-items: left;
	align-items: left;
	background-color: #d2dbdd;
`;

const Message = styled.div`
	grid-column-start: 2;
	border-left: solid #000000;
	padding: 3%;
	padding-top: 1vh;
	width: 100%;
`;

function Home({sok, setSok, loggedIn, history}) {
	const [bedriftInfo, setBedriftInfo] = useState('None');
	const [ansatte, setAnsatte] = useState('');
	const userInfo = useSelector((state) => state.userInfo);
	const {authState, authService} = useOktaAuth();

	const dispatch = useDispatch();

	useEffect(() => {
		const getUser = async () => {
			if (!authState.isAuthenticated) {
				// When user isn't authenticated, forget any user info
				dispatch(setUserInfoNone());
			} else {
				if (userInfo.name === '') {
					await authService
						.getUser()
						.then(async (info) => {
							if (userInfo.name === '' && info === undefined) {
								alert('Det anbefales at du logger ut og inn!');

								dispatch(
									setUserInfo(
										JSON.parse(
											localStorage.getItem('userInfo')
										)
									)
								);
							} else {
								if (info !== undefined) {
									dispatch(setUserInfo(info));
									localStorage.setItem(
										'userInfo',
										JSON.stringify(info)
									);
								} else {
									dispatch(setUserInfo(info));
								}
							}
							await axios
								.get(
									`${BACKEND_URL}getSekreter/${
										info === undefined
											? userInfo.email
											: info.email
									}`,
									{
										headers: {
											authorization: `Bearer ${await authService.getAccessToken()}`,
										},
									}
								)
								.then(async (res) => {
									if (res.data === 'No connection') {
										dispatch(setDefaultSekreter());
									}
									dispatch(
										updateSekreter(res.data.sekreter[0])
									);
								});
							toast.success('🧩 Velkommen', {
								position: 'top-right',
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
							});
						})
						.catch(async (err) => {
							console.log(err);
						});
				}
			}
		};
		getUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []); // Update if authState changes

	return (
		<Wrapper>
			<Bedriftsok
				setSok={(val) => setSok(val)}
				bedriftInfo={bedriftInfo}
				setBedriftInfo={(info) => {
					setBedriftInfo(info);
				}}
				ansatte={ansatte}
				setAnsatte={(ansatte) => {
					setAnsatte(ansatte);
				}}
				loggedIn={loggedIn}
			/>
			<Message>
				<MessageField sok={sok} />
			</Message>
		</Wrapper>
	);
}

export default Home;
