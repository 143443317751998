/** @format */

const sektreterReduser = (
	state = {
		sekreterID: '',
		fornavn: '',
		etternavn: '',
		telefon: '',
		privatEpost: '',
		firmaEpost: '',
		opptatt: false,
		bedriftID: '',
	},
	action
) => {
	let sekreter = {
		sekreterID: '',
		fornavn: '',
		etternavn: '',
		telefon: '',
		privatEpost: '',
		firmaEpost: '',
		opptatt: false,
		bedriftID: '',
	};

	switch (action.type) {
		case 'UPDATE_SEKRETER':
			for (let i = 0; i < Object.keys(action.payload).length; i++) {
				state = {
					...state,
					[Object.keys(action.payload)[i]]:
						action.payload[Object.keys(action.payload)[i]],
				};
			}
			return state;
		case 'SET_SEKRETER_NONE':
			return {};
		case 'SET_SEKRETER_DEFAULT':
			return sekreter;
		default:
			return state;
	}
};

export default sektreterReduser;
