/** @format */

export const updateBeskjed = (value) => {
	return {
		type: 'UPDATE_BESKJED',
		payload: value,
	};
};

export const setDefaultBeskjed = () => {
	return {
		type: 'SET_DEFAULT_BESKJED',
	};
};
