/** @format */

import {BACKEND_URL} from './variables.js';
import {toast} from 'react-toastify';

toast.configure();

const axios = require('axios');

export async function processAnsatte(id, token) {
	let groups = [];
	let ansatte = [];
	let defaultAnsatte = [];
	await axios
		.get(`${BACKEND_URL}getAnsattFromBedrift/${id}`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
		.then((res) => {
			if (res.data === 'No connection') {
				alert(
					'Kunne ikke hente ansatte. Har du internett forbindelse?'
				);
			} else {
				for (let i = 0; i < res.data.ansatte.length; i++) {
					let label =
						res.data.ansatte[i].fornavn +
						' ' +
						res.data.ansatte[i].etternavn;
					let sokeOrd =
						res.data.ansatte[i].nokkelStikkord +
						' ' +
						res.data.ansatte[i].info1 +
						' ' +
						res.data.ansatte[i].info2;
					ansatte.push({
						label: res.data.ansatte[i].nokkelStikkord,
						options: [
							{
								value: res.data.ansatte[i].ansattID,
								label: label,
								tag: res.data.ansatte[i].nokkelStikkord,
								sokeOrd: sokeOrd,
							},
						],
					});
					let ansattGroups = res.data.ansatte[i].nokkelStikkord.split(
						','
					);
					ansattGroups.forEach((group) => {
						let tempGroup = groups.filter(
							(g) => g.label === group.trim()
						);
						tempGroup.length === 0
							? groups.push({
									label: group.trim(),
									options: [
										{
											label: label,
											value: res.data.ansatte[i].ansattID,
											tag: group.trim(),
											sokeOrd: sokeOrd,
										},
									],
							  })
							: tempGroup[0].options.push({
									label: label,
									value: res.data.ansatte[i].ansattID,
									tag: group.trim(),
									sokeOrd: sokeOrd,
							  });
					});
				}
				defaultAnsatte = res.data.ansatte;
				return [ansatte, groups, res.data.ansatte];
			}
		})
		.catch((err) => {
			if (err.response.status === 500) {
				console.log('Får ikke kontakt med databasen');
			}
			console.log(err);
		});
	return [ansatte, groups, defaultAnsatte];
}

export async function getAlleBedrifter(token) {
	let temp = [];
	await axios
		.get(`${BACKEND_URL}getBedrifter`, {
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
		.then((res) => {
			if (res === 'No connection') {
				console.log('Ikke kontakt med databasen');
			} else {
				for (let i = 0; i < res.data.length; i++) {
					temp.push({
						label: res.data[i].firmaNavn,
						value: res.data[i].bedriftID.toString(),
					});
				}
				return temp;
			}
		})
		.catch((err) => {
			if (err.response.status === 500) {
				console.log('Får ikke kontakt med databasen');
			}
			console.log(err);
		});
	return temp;
}

export async function sendEmailHelper(token, beskjed, sekreter) {
	let success = false;
	await axios
		.post(
			`${BACKEND_URL}sendEmail`,
			{
				email: beskjed.epost,
				beskjed: beskjed,
				sekreter: sekreter,
			},
			{
				headers: {
					authorization: `Bearer ${token}`,
				},
			}
		)
		.then((res) => {
			success = true;
		})
		.catch((err) => {
			success = false;
			toast.error(
				'Kunne ikke sende mail. Beskjed ikke lagret.\nPrøv igjen',
				{
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);
			console.log(err);
		});
	return success;
}

export async function sendSmsHelper(token, beskjed) {
	let success = false;
	const MSG =
		'Beskjed fra ' +
		beskjed.fra +
		'\n\n' +
		beskjed.beskjed +
		'\n\nRing tilbake på +47 ' +
		beskjed.fraTelefon +
		'\n\nMvh FrankeSjøberg AS';

	await axios
		.post(
			`${BACKEND_URL}sendSms`,
			{
				til:
					beskjed.telefon2 !== null
						? [beskjed.telefon1, beskjed.telefon2]
						: beskjed.telefon1,
				beskjed: MSG,
			},
			{
				headers: {
					authorization: `Bearer ${token}`,
				},
			}
		)
		.then((res) => {
			success = true;
		})
		.catch((err) => {
			success = false;
			console.log(err);
		});
	return success;
}

export async function sendBeskjed(beskjed, ansatt, sekreter, token) {
	let mail = false;
	let sms = false;

	beskjed = {...ansatt, ...beskjed};

	if (ansatt.beskjedMail && ansatt.beskjedSms) {
		mail = await sendEmailHelper(token, beskjed, sekreter);
		sms = await sendSmsHelper(token, beskjed);
	} else if (ansatt.beskjedMail) {
		mail = await sendEmailHelper(token, beskjed, sekreter);
	} else if (ansatt.beskjedMail) {
		sms = await sendSmsHelper(token, beskjed);
	}

	return {mail: mail, sms: sms};
}

export async function createBeskjed(beskjed, token) {
	return await axios.post(
		`${BACKEND_URL}createBeskjed`,
		{
			beskjed: beskjed,
		},
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
}

export const postOpptatt = async (sekreter, token) => {
	return await axios.post(
		`${BACKEND_URL}setOpptatt`,
		{sekreter: sekreter},
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
};

export const postTilgjengelig = async (sekreter, token) => {
	return await axios.post(
		`${BACKEND_URL}setTilgjengelig`,
		{sekreter: sekreter},
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
};

export const updateTilgjengeligStatus = async (sekreter, opptatt, token) => {
	return await axios.post(
		`${BACKEND_URL}updateTilgjengelig`,
		{sekreter: sekreter, opptatt: opptatt},
		{
			headers: {
				authorization: `Bearer ${token}`,
			},
		}
	);
};
