/** @format */

const beskjedReduser = (
	state = {
		bedriftID: '',
		ansattID: '',
		fra: '',
		fraTelefon: '',
		beskjed: '',
		skjultInfo: '',
		tid: '',
		stoppDenne: false,
		kunStatistikk: false,
		fireMinutter: false,
		sattOver: false,
		haster: false,
		vent: false,
		godkjent: false,
		sekreterID: '',
		egenkontroll: false,
	},
	action
) => {
	const defaultState = {
		bedriftID: '',
		ansattID: '',
		fra: '',
		fraTelefon: '',
		beskjed: '',
		skjultInfo: '',
		tid: '',
		stoppDenne: false,
		kunStatistikk: false,
		fireMinutter: false,
		sattOver: false,
		haster: false,
		vent: false,
		godkjent: false,
		sekreterID: '',
		egenkontroll: false,
	};

	switch (action.type) {
		case 'UPDATE_BESKJED':
			for (let i = 0; i < Object.keys(action.payload).length; i++) {
				state = {
					...state,
					[Object.keys(action.payload)[i]]:
						action.payload[Object.keys(action.payload)[i]],
				};
			}
			return state;
		case 'SET_DEFAULT_BESKJED':
			return defaultState;
		default:
			return state;
	}
};

export default beskjedReduser;
