/** @format */

const ansattReduser = (
	ansatt = {
		ansattID: '',
		fornavn: '',
		etternavn: '',
		telefon1: '',
		trioKode: '',
		info1: '',
		set: false,
	},
	action
) => {
	const defaultAnsatt = {
		ansattID: '',
		fornavn: '',
		etternavn: '',
		telefon1: '',
		trioKode: '',
		info1: '',
		set: false,
	};

	switch (action.type) {
		case 'UPDATE_ANSATT':
			for (let i = 0; i < Object.keys(action.payload).length; i++) {
				ansatt = {
					...ansatt,
					[Object.keys(action.payload)[i]]:
						action.payload[Object.keys(action.payload)[i]],
				};
			}
			return ansatt;
		case 'UPDATE_ANSATT_SET':
			return {...ansatt, set: action.payload};
		case 'SET_DEFAULT_ANSATT':
			return defaultAnsatt;
		default:
			return ansatt;
	}
};

export default ansattReduser;
