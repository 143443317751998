/** @format */

export const updateSekreter = (newState) => {
	return {
		type: 'UPDATE_SEKRETER',
		payload: newState,
	};
};

export const setDefaultSekreter = () => {
	return {
		type: 'SET_SEKRETER_DEFAULT',
	};
};

export const setUserInfo = (newState) => {
	return {
		type: 'UPDATE_USER_INFO',
		payload: newState,
	};
};

export const setUserInfoNone = () => {
	return {
		type: 'SET_USER_INFO_NONE',
	};
};
