/** @format */

import React from 'react';
import {
	Security,
	LoginCallback,
	SecureRoute,
	AuthService,
} from '@okta/okta-react';
import {Route, withRouter} from 'react-router-dom';

import './App.css';

import Login from './components/Auth/LoginForm.jsx';
import Admin from './components/Admin/Admin';
import Entry from './components/Entry/Entry.jsx';

const App = ({history}) => {
	const onAuthRequired = () => {
		history.push('/login');
	};

	var authService = new AuthService({
		issuer: 'https://dev-653870.okta.com/oauth2/default',
		clientId: '0oajf4psjdaxkwJp24x6',
		redirectUri: window.location.origin + '/implicit/callback',
		scope: ['openid', 'email', 'profile', 'groups', 'offline_access'],
		pkce: true,
		onAuthRequired: onAuthRequired,
	});

	const EntryComponent = () => <Entry history={history} />;

	const AdminComponent = () => <Admin history={history} />;

	try {
		return (
			<Security authService={authService}>
				<SecureRoute exact path='/' component={EntryComponent} />
				<Route
					path='/login'
					render={() => (
						<Login issuer='https://dev-653870.okta.com/oauth2/default' />
					)}
				/>
				<SecureRoute path='/admin' component={AdminComponent} />
				<Route path='/implicit/callback' component={LoginCallback} />
			</Security>
		);
	} catch {
		return <div>Har ikke internett</div>;
	}
};

export default withRouter(App);
