/** @format */

const alleskontrollReduser = (
	state = {
		bedriftID: '',
		ansattID: '',
		fra: '',
		fraTelefon: '',
		beskjed: '',
		skjultInfo: '',
		tid: '',
		stoppDenne: false,
		kunStatistikk: false,
		fireMinutter: false,
		sattOver: false,
		haster: false,
		vent: false,
		godkjent: false,
		sekreterID: '',
	},
	action
) => {
	switch (action.type) {
		case 'UPDATE_ALLESKONTROLL_BEDRIFT_ID':
			console.log('BedriftID: ' + action.payload);
			state.bedriftID = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_ANSATT_ID':
			state.ansattID = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_FRA':
			state.fra = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_FRA_TELEFON':
			state.fraTelefon = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_BESKJED':
			state.beskjed = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_SKJULT_INFO':
			state.skjultInfo = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_TID':
			state.tid = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_STOPP_DENNE':
			state.stoppDenne = !state.stoppDenne;
			return state;
		case 'UPDATE_ALLESKONTROLL_KUN_STATISTIKK':
			state.kunStatistikk = !state.kunStatistikk;
			return state;
		case 'UPDATE_ALLESKONTROLL_FIRE_MINUTTER':
			state.fireMinutter = !state.fireMinutter;
			return state;
		case 'UPDATE_ALLESKONTROLL_SATT_OVER':
			state.sattOver = !state.sattOver;
			return state;
		case 'UPDATE_ALLESKONTROLL_HASTER':
			state.haster = !state.haster;
			return state;
		case 'UPDATE_ALLESKONTROLL_VENT':
			state.vent = !state.vent;
			return state;
		case 'UPDATE_ALLESKONTROLL_GODKJENT':
			state.godkjent = action.payload;
			return state;
		case 'UPDATE_ALLESKONTROLL_SEKRETER_ID':
			state.sekreterID = action.payload;
			return state;
		default:
			return state;
	}
};

export default alleskontrollReduser;
