/** @format */

// React imports
import React, {useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';

// Misc
import {BACKEND_URL} from '../../misc/variables';

// Hooks
import {useInterval} from '../Hooks/Hooks.js';

// Component imports
import Home from '../Home/Home';
import Navbar from '../Navbar/Navbar.jsx';

// Constants
const axios = require('axios');

const Entry = ({history}) => {
	const [sok, setSok] = useState();
	const {authState} = useOktaAuth();
	const [loggedIn, setLoggedIn] = useState([]);

	useInterval(async () => {
		await axios
			.get(`${BACKEND_URL}getLoggedInSekreterer`, {
				params: {token: authState.accessToken},
			})
			.then((res) => {
				setLoggedIn(res.data.map((s) => s.navn));
			})
			.catch((err) => {
				console.log(err);
			});
	}, 10000);

	return (
		<div id='app'>
			<Navbar
				issuer='https://dev-653870.okta.com/oauth2/default'
				history={history}
			/>
			<Home
				setSok={(val) => setSok(val)}
				sok={sok}
				issuer='https://dev-653870.okta.com/oauth2/default'
				redirectUri={window.location.origin + '/implicit/callback'}
				loggedIn={loggedIn}
				history={history}
			/>
		</div>
	);
};

export default Entry;
