/** @format */

export const updateAnsatt = (value) => {
	return {
		type: 'UPDATE_ANSATT',
		payload: value,
	};
};

export const updateAnsattSet = (value) => {
	return {
		type: 'UPDATE_ANSATT_SET',
		payload: value,
	};
};

export const setDefaultAnsatt = () => {
	return {
		type: 'SET_DEFAULT_ANSATT',
	};
};
