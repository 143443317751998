/** @format */

// React imports
import {useOktaAuth} from '@okta/okta-react';
import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';

// Redux imports
import {updateSekreter} from '../../actions';

// Helpers imports
import {LOGOUT_REDIRECT_URI} from '../../misc/variables.js';
import {updateTilgjengeligStatus} from '../../misc/helpers.js';

// Component imports
import {
	Navbar,
	NavbarToggler,
	NavbarBrand,
	Nav,
	NavItem,
	NavLink,
	NavbarText,
} from 'reactstrap';

import Clock from '../Clock/Clock';

// Constants

const NavBar = ({issuer, history}) => {
	const {authState, authService} = useOktaAuth();
	const [isOpen, setIsOpen] = useState(false);
	const userInfo = useSelector((state) => state.userInfo);
	const sekreter = useSelector((state) => state.sekreter);
	const dispatch = useDispatch();

	const toggle = () => setIsOpen(!isOpen);

	const logout = async () => {
		await updateTilgjengeligStatus(sekreter, 1, authState.accessToken)
			.then(async (res) => {
				// Read idToken before local session is cleared
				localStorage.removeItem('userInfo');
				const idToken = authState.idToken;

				// Clear remote session
				if (idToken) {
					await authService.logout('/');
					window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${LOGOUT_REDIRECT_URI}`;
				} else {
					await authService.logout('/login');
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const handleTilgjengeligClick = async () => {
		await updateTilgjengeligStatus(
			sekreter,
			!sekreter.opptatt,
			authState.accessToken
		)
			.then((res) => {
				if (res.status === 200) {
					dispatch(updateSekreter({opptatt: !sekreter.opptatt}));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const tilgengeligStatus = (
		<span
			style={{
				color: sekreter.opptatt ? '#e86161' : '#78e861',
				cursor: 'pointer',
			}}
			onClick={handleTilgjengeligClick}>
			{sekreter.opptatt ? '(Utlogget)' : '(Innlogget)'}
		</span>
	);

	return (
		<div>
			<Navbar
				style={{
					backgroundColor: '#6e7475',
				}}
				dark
				// fixed='top'
				expand='md'>
				<NavbarBrand
					style={{cursor: 'pointer'}}
					onClick={() => {
						history.push('/');
					}}>
					{userInfo.name}
				</NavbarBrand>
				{tilgengeligStatus}
				<NavbarToggler onClick={toggle} />
				<Nav className='mr-auto' navbar>
					<NavItem>
						<NavLink
							style={{cursor: 'pointer'}}
							onClick={() => {
								history.push('/');
							}}>
							Hjem
						</NavLink>
					</NavItem>
					<NavItem>
						{userInfo.groups.includes('Admin') && (
							<NavLink
								style={{cursor: 'pointer'}}
								onClick={() => {
									history.push('/admin');
								}}>
								Admin
							</NavLink>
						)}
					</NavItem>
					<NavItem>
						<NavLink
							style={{
								cursor: 'pointer',
								textDecoration: 'underline',
							}}
							onClick={logout}>
							Logg ut
						</NavLink>
					</NavItem>
				</Nav>
				<NavbarText>
					<Clock />
				</NavbarText>
			</Navbar>
		</div>
	);
};
export default NavBar;
