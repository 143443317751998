/** @format */

// React imports
import React, {useEffect} from 'react';
import styled from 'styled-components';

// Component imports
import Select from 'react-select';

//Misc inmport
import {BACKEND_URL} from '../../misc/variables.js';

// Constants

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
	justify-self: left;
	align-self: left;
	grid-row: 2/2;
`;

const SokBedrift = styled(Select)`
	grid-row: 1/1;
	width: 100%;
	margin-bottom: 2%;
`;

const GenerateKundeInfo = ({
	getBedrifter,
	handleBedriftChange,
	bedriftChosen,
	bedrifter,
	bedriftID,
	authService,
}) => {
	useEffect(() => {
		getBedrifter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleButtonClick = async () => {
		const link = document.createElement('a');
		link.href =
			BACKEND_URL +
			`generateKundeInfo/${bedriftID}?token=${await authService.getAccessToken()}`;
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	};

	return (
		<Wrapper>
			<SokBedrift
				isClearable
				isSearchable
				options={bedrifter}
				onChange={handleBedriftChange}
				placeholder='Velg bedrift'
			/>
			{bedriftChosen && (
				<button onClick={handleButtonClick}>
					Last ned kundeinfo for valgt bedrift
				</button>
			)}
		</Wrapper>
	);
};

export default GenerateKundeInfo;
