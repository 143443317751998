/** @format */

// React imports
import React, {useState} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';

// Helpers imports
import {BACKEND_URL} from '../../misc/variables.js';

// Component imports
import {Form, Input, Row, Col, Label, FormGroup, Button} from 'reactstrap';

// Constatnts

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
	justify-self: left;
	align-self: left;
	grid-row: 2/2;
`;

const GenererLonn = () => {
	const [formVals, setFormVals] = useState({});
	const {authService} = useOktaAuth();

	const handleChange = (e) => {
		setFormVals({...formVals, [e.target.name]: e.target.value});
	};

	const handleButtonClick = async (e) => {
		const route =
			e.target.name === 'telefoner'
				? 'generateTelefoner'
				: 'generateLonn';
		const link = document.createElement('a');
		link.href =
			BACKEND_URL +
			route +
			`?fraDato=${formVals.fraDato}&tilDato=${
				formVals.tilDato
			}&token=${await authService.getAccessToken()}`;

		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	};

	return (
		<Wrapper>
			<Form>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for='fraDato'>Fra</Label>
							<Input
								type='date'
								id='fraDato'
								name='fraDato'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for='tilDato'>Til</Label>
							<Input
								type='date'
								id='tilDato'
								name='tilDato'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				{formVals.fraDato !== undefined &&
				formVals.tilDato !== undefined &&
				formVals.fraDato <= formVals.tilDato ? (
					<Row>
						<FormGroup className='mr-sm-5 mt-sm-2'>
							<Button
								name='lonnsgrunnlag'
								onClick={handleButtonClick}>
								Last ned lønnsgrunnlag
							</Button>
						</FormGroup>
						<FormGroup className='mr-sm-2 mt-sm-2'>
							<Button
								name='telefoner'
								onClick={handleButtonClick}>
								Last ned telefoner
							</Button>
						</FormGroup>
					</Row>
				) : (
					''
				)}
			</Form>
		</Wrapper>
	);
};

export default GenererLonn;
