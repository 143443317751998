/** @format */

// React imports
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useOktaAuth} from '@okta/okta-react';
import {toast} from 'react-toastify';

// Components imports
import {Form, FormGroup, Label, Input, Button, Row, Col} from 'reactstrap';
import Select from 'react-select';

// Helpers imports
import {BACKEND_URL} from '../../misc/variables.js';

// CSS
import 'react-toastify/dist/ReactToastify.css';

// Configure toast
toast.configure();

// Constants
const generatePassword = require('password-generator');

const axios = require('axios');

const Wrapper = styled.div`
	margin-left: 1em;
	diplay: grid;
	grid-auto-rows: (auto-fit, minmax(min-content, max-content));
	grid-auto-columns: (auto-fit, minmax(min-content, max-content));
	width: 95%;
	justify-self: left;
	align-self: left;
	grid-row: 2/2;
`;

const RegisterForm = () => {
	const [user, setUser] = useState({
		firstName: '',
		lastName: '',
		email: '',
		mobilePhone: '',
		password: generatePassword(10, false),
	});
	const [values, setValues] = useState([]);
	const {authState, authService} = useOktaAuth();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		getAndSetSekreterer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = async () => {
		setLoading(true);
		axios
			.post(`${BACKEND_URL}newSekreter`, user, {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then(async (res) => {
				if (res.status === 201) {
					setLoading(false);
					toast.success(
						`${user.firstName} ${user.lastName} ble registrert! Personen må aktivere brukeren sin med link fra epost.`
					);
				}
			})
			.catch((err) => {
				setLoading(false);
				if (err.response.status === 409) {
					toast.error('Denne eposten er allerede registrert', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				} else if (err.response.status === 400) {
					toast.error(err.response.data.errorCauses[0].errorSummary, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
				}
				console.log(err);
			});
	};

	const getAndSetSekreterer = async () => {
		await axios
			.get(`${BACKEND_URL}getSekreterer`, {
				headers: {
					authorization: `Bearer ${authState.accessToken}`,
				},
			})
			.then((res) => {
				setValues(
					res.data.map((s) => ({
						label: (
							<div>
								{s.name}
								<span
									style={{
										fontSize: '90%',
										display: 'block',
										marginTop: '-.4em',
									}}>
									Ansvarlig for{' '}
									{s.ansvarligFor === null
										? 'ingen'
										: s.ansvarligFor}
								</span>{' '}
							</div>
						),
						value: s.sekreterID,
						name: s.name,
					}))
				);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const filterOptions = (candidate, value) => {
		if (
			candidate.data.name
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim()) ||
			candidate.data.value
				.toString()
				.toLocaleLowerCase()
				.trim()
				.includes(value.toLocaleLowerCase().trim())
		)
			return true;
	};

	const handleChange = (e) => {
		setUser({...user, [e.target.name]: e.target.value});
	};

	const onSelectChange = (e, {action}) => {
		setUser({...user, ansvarFor: parseInt(e.value)});
	};

	return (
		<Wrapper>
			<Form onChange={handleChange}>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for='firstName'>Fornavn</Label>
							<Input
								type='text'
								id='firstName'
								name='firstName'
								disabled={loading}
							/>
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for='lastName'>Etternavn</Label>
							<Input
								type='text'
								id='lastName'
								name='lastName'
								disabled={loading}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={6}>
						<FormGroup>
							<Label for='email'>Epost</Label>
							<Input
								type='email'
								id='email'
								name='email'
								disabled={loading}
							/>
						</FormGroup>
					</Col>
					<Col md={6}>
						<FormGroup>
							<Label for='mobilePhone'>Telefon</Label>
							<Input
								type='moblie'
								id='mobilePhone'
								name='mobilePhone'
								disabled={loading}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={12}>
						<FormGroup>
							<Label for='ansvarlig'>Ansvar for</Label>
							<Select
								options={values}
								name='ansvarlig'
								id='ansvarlig'
								onChange={onSelectChange}
								isSearchable
								filterOption={filterOptions}
								disabled={loading}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Button onClick={handleSubmit} disabled={loading}>
					Registrer
				</Button>
			</Form>
		</Wrapper>
	);
};

export default RegisterForm;
