/** @format */

// React imports
import React, {useState} from 'react';
import {useInterval} from '../Hooks/Hooks';
import {useOktaAuth} from '@okta/okta-react';
import styled from 'styled-components';
import {BACKEND_URL} from '../../misc/variables.js';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

// Helpers
import {sendEmailHelper, sendSmsHelper} from '../../misc/helpers.js';

// CSS
import 'react-toastify/dist/ReactToastify.css';

// Component imports
import {Badge} from 'reactstrap';
import {
	Form,
	FormGroup,
	Input,
	Button,
	Col,
	Row,
	CustomInput,
	Label,
} from 'reactstrap';

// Configure toast
toast.configure();

// Constants
const axios = require('axios');

const Br = styled.span`
	display: block;
	margin-bottom: 2.3em;
`;

const BrLarge = styled.span`
	display: block;
	margin-bottom: 3em;
`;

const EgenkontrollHead = styled.div`
	font-size: 1.5em;
`;

const Egenkontroll = ({loggedIn}) => {
	const sekreter = useSelector((state) => state.sekreter);
	const [beskjeder, setBeskjeder] = useState([]);
	const [focus, setFocus] = useState(false);
	const {authState, authService} = useOktaAuth();

	const defaultBeskjed = {
		beskjedID: '',
		bedriftID: '',
		ansattID: '',
		fra: '',
		fraTelefon: '',
		beskjed: '',
		skjultInfo: '',
		tid: '',
		stoppDenne: false,
		kunStatistikk: false,
		fireMinutter: false,
		sattOver: false,
		haster: false,
		vent: false,
		godkjent: false,
		sekreterID: '',
		fornavn: '',
		etternavn: '',
		fullname: '',
		firmaNavn: '',
		beskjedMail: '',
		beskjedSms: '',
		epost: '',
		telefon1: '',
		telefon2: '',
		kommentar: '',
		ansvarligSekreter: '',
	};

	const [beskjed, setBeskjed] = useState(defaultBeskjed);
	const [vent, setVent] = useState(false);

	useInterval(() => {
		getAndSetBeskjeder();
	}, 10000);

	const getAndSetBeskjeder = async () => {
		if (sekreter.sekreterID === null || sekreter.ansvarFor === null) {
			return;
		}
		return await axios
			.get(
				`${BACKEND_URL}getEgenkontroll/${sekreter.sekreterID}&${sekreter.ansvarFor}`,
				{
					headers: {
						authorization: `Bearer ${await authService.getAccessToken()}`,
					},
				}
			)
			.then((res) => {
				if (res.status === 500) {
					console.log('Kunne ikke hente egenkontroller');
				} else if (res.data.length > 0 && !focus) {
					setBeskjeder(res.data);
					setVent(res.data[0].vent);
					setBeskjed(res.data[0]);
				} else {
					if (res.data.length === 0) {
						setVent(false);
					}
					if (beskjeder.length !== 0 || beskjed.beskjedID !== '') {
						setBeskjeder([]);
						setBeskjed(defaultBeskjed);
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const moveToNextBeskjed = () => {
		let tempBeskjeder = beskjeder.filter(
			(b) => b.beskjedID !== beskjed.beskjedID
		);
		if (tempBeskjeder.length === 0) {
			setBeskjeder([]);
			setBeskjed(defaultBeskjed);
		} else {
			setBeskjeder(tempBeskjeder);
			setVent(tempBeskjeder[0].vent);
			setBeskjed(tempBeskjeder[0]);
		}
	};

	const next = () => {
		if (beskjeder.length > 1) {
			const first = beskjeder.shift();
			setBeskjeder(beskjeder);
			setBeskjed(beskjeder[0]);
			let tempBeskjeder = [...beskjeder, first];
			setVent(tempBeskjeder[0].vent);
			setBeskjeder(tempBeskjeder);
		}
	};

	const prev = () => {
		if (beskjeder.length > 1) {
			const last = beskjeder.pop();
			setBeskjeder(beskjeder);
			setVent(last.vent);
			setBeskjed(last);
			let tempBeskjeder = [last, ...beskjeder];
			setBeskjeder(tempBeskjeder);
		}
	};

	const send = async () => {
		let success;
		let melding;
		await axios
			.get(`${BACKEND_URL}getSekreterFromID/${beskjed.sekreterID}`, {
				headers: {
					authorization: `Bearer ${await authService.getAccessToken()}`,
				},
			})
			.then(async (res) => {
				if (beskjed.beskjedMail && beskjed.beskjedSms) {
					success =
						(await sendEmailHelper(
							await authService.getAccessToken(),
							beskjed,
							res.data.sekreter
						)) &&
						(await sendSmsHelper(
							await authService.getAccessToken(),
							beskjed
						));
					melding = 'Email og Sms er sendt!';
				} else if (beskjed.beskjedMail) {
					success = await sendEmailHelper(
						await authService.getAccessToken(),
						beskjed,
						res.data.sekreter
					);
					melding = 'Email er sendt!';
				} else if (beskjed.beskjedSms) {
					success = await sendSmsHelper(
						await authService.getAccessToken(),
						beskjed
					);
					melding = 'Sms er sendt!';
				} else {
					melding = `Valgt ansatt har ikke registert noen meldingspreferanse. Beskjed ikke sendt.`;
					success = false;
				}
			});

		if (success) {
			await axios
				.post(
					BACKEND_URL + 'alterBeskjed',
					{
						beskjedID: beskjed.beskjedID,
						beskjed: beskjed.beskjed,
						skjultInfo: beskjed.skjultInfo,
						stoppDenne: beskjed.stoppDenne,
						kunStatistikk: beskjed.kunStatistikk,
						fireMinutter: beskjed.fireMinutter,
						sattOver: beskjed.sattOver,
						haster: beskjed.haster,
						vent: beskjed.vent,
						egenkontroll: true,
						godkjent: true,
						godkjentAv: sekreter.sekreterID,
					},
					{
						headers: {
							authorization: `Bearer ${await authService.getAccessToken()}`,
						},
					}
				)
				.then((res) => {
					toast.success(melding, {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					moveToNextBeskjed();
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			toast.info(
				`Valgt ansatt har ikke registert noen meldingspreferanse. Beskjed ikke sendt.`,
				{
					position: 'top-right',
					autoClose: 10000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				}
			);
		}
	};

	const handleChange = (e) => {
		const checkBoxes = [
			'stoppDenne',
			'kunStatistikk',
			'fireMinutter',
			'sattOver',
			'haster',
			'vent',
		];
		if (checkBoxes.includes(e.target.name)) {
			setBeskjed({
				...beskjed,
				[e.target.name]: e.target.checked,
			});
		} else {
			setBeskjed({...beskjed, [e.target.name]: e.target.value});
		}
	};

	const handleUnderkjent = async () => {
		if (beskjed.sekreterID === sekreter.sekreterID && !beskjed.vent) {
			toast.error('Du kan ikke underkjenne din egen beskjed', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			await axios
				.post(
					`${BACKEND_URL}alterBeskjed`,
					{
						...beskjed,
						retting:
							beskjed.vent &&
							!vent &&
							beskjed.sekreterID !== sekreter.sekreterID
								? 0
								: beskjed.vent &&
								  vent &&
								  beskjed.sekreterID !== sekreter.sekreterID
								? 0
								: beskjed.vent && !beskjed.retting
								? 0
								: 1,
					},
					{
						headers: {
							authorization: `Bearer ${authState.accessToken}`,
						},
					}
				)
				.then((res) => {
					if (!beskjed.vent) {
						toast.success('Beskjed sendt til retting', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						moveToNextBeskjed();
					} else {
						toast.success('Lagret!', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
					}
				})
				.catch((err) => {
					toast.error('Noe feil skjedde', {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					console.log(err);
				});
		}
	};

	const handleGodkjent = async () => {
		if (beskjed.vent) {
			toast.info('Du må fjerne vent før du gjør dette', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		} else {
			if (
				(beskjed.retting &&
					loggedIn.includes(beskjed.ansvarligSekreter)) ||
				(vent && beskjed.retting) ||
				(beskjed.ansvarligSekreter !==
					sekreter.fornavn + ' ' + sekreter.etternavn &&
					loggedIn.includes(beskjed.ansvarligSekreter))
			) {
				await axios
					.post(
						`${BACKEND_URL}alterBeskjed`,
						{...beskjed, retting: 0},
						{
							headers: {
								authorization: `Bearer ${authState.accessToken}`,
							},
						}
					)
					.then((res) => {
						toast.success('Beskjed sendt til godkjenning', {
							position: 'top-right',
							autoClose: 5000,
							hideProgressBar: false,
							closeOnClick: true,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
						});
						moveToNextBeskjed();
					})
					.catch((err) => {
						console.log(err);
					});
			} else {
				send();
			}
		}
	};

	const hentNye = async () => {
		getAndSetBeskjeder().then(() => {
			toast.success('Hentet egenkontroller', {
				position: 'top-right',
				autoClose: 2000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		});
	};

	return (
		<div>
			<EgenkontrollHead>
				Egenkontroll{'   '}
				{beskjeder.length === 0 ? (
					<Badge color='secondary'>0</Badge>
				) : (
					<Badge color='success'>
						{beskjed.counter} / {beskjeder.length}
					</Badge>
				)}
				:
			</EgenkontrollHead>
			<Form
				onFocus={() => {
					setFocus(true);
				}}
				onBlur={() => {
					setFocus(false);
				}}>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='bedrift'>Bedrift</Label>
							<Input
								value={beskjed.firmaNavn}
								disabled
								type='text'
								placeholder='Bedrift'
								name='bedrift'
								id='egenkontrollBedrift'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.stoppDenne}
								name='stoppDenne'
								id='egenkontrollStoppDenne'
								label='Bare sluttrapport (stopp denne)'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='ansatt'>Kontakt i bedrift</Label>
							<Input
								value={beskjed.fullname}
								disabled
								type='text'
								placeholder='Kontakt i bedrift'
								name='ansatt'
								id='egenkontrollAnsatt'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.fireMinutter}
								name='fireMinutter'
								id='egenkontrollFireMinutter'
								label='4 Minutter'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='fra'>Fra</Label>
							<Input
								value={beskjed.fra}
								disabled
								type='text'
								placeholder='Fra'
								name='fra'
								id='egenkontrollFra'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<Br />
							<CustomInput
								type='switch'
								checked={beskjed.sattOver}
								name='sattOver'
								id='egenkontrollSattOver'
								label='Satt over'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='fraTelefon'>Fra telefon</Label>
							<Input
								value={beskjed.fraTelefon}
								disabled
								type='text'
								id='egenkontrollFraTelefon'
								placeholder='Fra telefon'
								name='fraTelefon'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<BrLarge />
							<CustomInput
								type='switch'
								checked={beskjed.haster}
								name='haster'
								id='egenkontrollHaster'
								label='Haster'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='beskjed'>Beskjed</Label>
							<Input
								value={beskjed.beskjed}
								type='textarea'
								placeholder='Beskjed'
								id='egenkontrollBeskjed'
								onChange={handleChange}
								name='beskjed'
							/>
						</FormGroup>
					</Col>
					<Col md={3}>
						<FormGroup>
							<BrLarge />
							<CustomInput
								type='switch'
								checked={beskjed.vent}
								name='vent'
								id='egenkontrollVent'
								label='Vent'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='ansvarligSekreter'>
								Ansvarlig sekretær
							</Label>
							<Input
								value={beskjed.ansvarligSekreter}
								type='text'
								placeholder='Ansvarlig Sekretær'
								name='ansvarligSekreter'
								id='egenkontrollansvarligSekreter'
								disabled
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='skjultInfo'>Internmelding</Label>
							<Input
								value={beskjed.skjultInfo}
								type='textarea'
								placeholder='Internmelding'
								name='skjultInfo'
								id='egenkontrollSkjultInfo'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row form>
					<Col md={9}>
						<FormGroup>
							<Label for='kommentar'>Kommentar</Label>
							<Input
								value={beskjed.kommentar}
								type='textarea'
								placeholder='Kommentar'
								name='kommentar'
								id='egenkontrollKommentar'
								onChange={handleChange}
							/>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<FormGroup>
							<Button onClick={prev}>Forrige</Button>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Button onClick={next}>Neste</Button>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col md={8}>
						<FormGroup>
							<Button color='danger' onClick={handleUnderkjent}>
								{(vent && beskjed.vent) || beskjed.vent
									? 'Lagre'
									: 'Underkjenn'}
							</Button>
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Button onClick={handleGodkjent} color='success'>
								{(beskjed.retting ||
									(vent && beskjed.retting) ||
									beskjed.ansvarligSekreter !==
										sekreter.fornavn +
											' ' +
											sekreter.etternavn) &&
								loggedIn.includes(beskjed.ansvarligSekreter)
									? 'Send til ansvarlig sekretær'
									: 'Godkjenn'}
							</Button>
						</FormGroup>
					</Col>
				</Row>
			</Form>
			<Button onClick={hentNye}>Oppdater egenkontroll</Button>
		</div>
	);
};

export default Egenkontroll;
